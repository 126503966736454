import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { Button } from 'semantic-ui-react';
import get from 'lodash.get';

import {
  donationI18Keys,
  donationSettings
} from './constants/donationSettings';
import { formatDecimalNumber } from '../../../helpers/converterHelper';

class DonationHeader extends Component {
  handleEditClick = () => {
    if (this.props.donation) {
      this.props.history.push(
        `/donations/edit/${this.props.donation.id}/donation-detail`
      );
    }
  };

  render() {
    const { financials, type, amount } = this.props.donation;
    const { isShowEmptyFields } = this.props;

    const currency = get(financials, 'currency') || 'N/A';
    const amountString = `$${formatDecimalNumber(amount, true)}`;
    const refunded = get(financials, 'refunded');
    const typeOfDonation = type || 'N/A';

    const donationPrimaryStatusBackgroundColor =
      financials &&
      donationSettings[typeOfDonation].getPrimaryStatusBackgroundColor(
        refunded
      );
    const donationSecondaryStatusBackgroundColor =
      financials &&
      donationSettings[typeOfDonation].getSecondaryStatusBackgroundColor();

    const donationPrimaryStatusValue =
      financials &&
      donationSettings[typeOfDonation].getPrimaryStatusValue(refunded);
    const donationSecondaryStatusValue =
      financials && donationSettings[typeOfDonation].getSecondaryStatusValue();

    return (
      <div className="donation-header">
        <div>{I18n.t(`${donationI18Keys.donationHeaderI18Key}.donation`)}</div>
        <div className="donation-header-content">
          <div className="first-section-block">
            <h1>{`${currency}${amountString}`}</h1>
            <div className="status-type">
              <span
                style={{ background: donationPrimaryStatusBackgroundColor }}
              >
                {donationPrimaryStatusValue}
              </span>
            </div>
            {donationSecondaryStatusValue && (
              <div className="status-type">
                <span
                  style={{ background: donationSecondaryStatusBackgroundColor }}
                >
                  {donationSecondaryStatusValue}
                </span>
              </div>
            )}
          </div>
          <div className="second-section-block">
            <div className="empty-fields-link">
              <p
                className="Links"
                onClick={this.props.changeVisibilityEmptyFields}
              >
                {isShowEmptyFields
                  ? I18n.t(
                      `${donationI18Keys.donationHeaderI18Key}.hide-empty-fields-link`
                    )
                  : I18n.t(
                      `${donationI18Keys.donationHeaderI18Key}.show-empty-fields-link`
                    )}
              </p>
            </div>
            <Button
              primary
              style={{ float: 'right', minWidth: 100 }}
              onClick={this.handleEditClick}
            >
              {I18n.t(
                `${donationI18Keys.donationHeaderI18Key}.show-edit-button`
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DonationHeader);
