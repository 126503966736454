import React from 'react';

import get from 'lodash.get';
import { paymentMethod } from '../../../../constants/paymentMethod';

export const DonationPaymentSectionRow = (props) => {
  const { title, data } = props;

  const bankAccountLast4Digits = get(data, 'bankAccountLast4Digits');
  const cardLast4Digits = get(data, 'cardLast4Digits');
  const paymentMethodOfDonation = get(data, 'paymentMethod');
  const cardBrand = get(data, 'cardBrand');

  return (
    <React.Fragment>
      {(bankAccountLast4Digits || cardLast4Digits) && (
        <div className="view-details-content-row column">
          <h4>{title}</h4>
          <div className="column-content">
            <span>{`XXXX ${bankAccountLast4Digits || cardLast4Digits}`}</span>
            {paymentMethodOfDonation === paymentMethod.creditCard && (
              <span>{cardBrand}</span>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
