import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import moment from 'moment';

import { Item, Grid, GridColumn, Breadcrumb, Header } from 'semantic-ui-react';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';

import RecurringStatus from './status';
import config from '../../../constants';
import { DATE_FORMAT, TIME_FORMAT } from '../constants';
import { formatDecimalNumber } from '../../../helpers/converterHelper';
import { getSearchString } from '../../../components/formBuilderAnswersComponents/formBuilderAnswersHelper';

import { ViewIconItem } from "../../../components/layouts/viewIconItem";
import { pageTypesStrings } from '../../../constants/pageTypes';
import { ReceiptBehaviour } from "./receipt-behaviour";

class DetailHeader extends Component {

  hasCampaign() {
    return !!this.props.recurringDetail?.campaign;
  }

  getCampaign() {
    return this.props.recurringDetail?.campaign;
  }

  getCampaignImage() {
    const campaign = this.getCampaign();
    return campaign?.mainImageUrl;
  }

  render() {
    const recurring = this.props.recurringDetail;
    const subscriptionNumber = recurring.subscriptionNumber;

    const startedAt = recurring.startedAtLocal;
    const date = moment(startedAt).format(DATE_FORMAT);
    const time = moment(startedAt).format(TIME_FORMAT);
    const dateTime = `${date} at ${time}`;

    const nextPaymentDue = recurring.nextPaymentDueAt;
    const nextPaymentDate = moment(nextPaymentDue).format(DATE_FORMAT);

    const accountName = `${recurring.donor.firstName} ${recurring.donor.lastName}`;
    const accountEmail = recurring.donor.email;
    const accountPhone = recurring.donor.phoneNumber;
    const accountAddress = getSearchString(recurring.donor.address);
    const frequency = recurring.frequency;
    const raisedAmount = `$${formatDecimalNumber(
      recurring.raisedAmount,
      true
    )}`;
    const amount = `$${formatDecimalNumber(
      recurring.amount,
      true
    )} - ${frequency}`;

    const managementUrl = recurring.managementUrl;

    const status = recurring.status;
    const donationCount = recurring.donationCount;
    const donationCountLimit = recurring.donationCountLimit;
    const isStatusWarningMessageVisible = status !== 'active';

    const showBehaviourSettings = !!recurring?.settings?.type || !!recurring?.settings?.receipt

    return (
      <Grid>
        <GridRow>
          <GridColumn width={11}>
            <Item.Group>
              <Item>
                <Item.Content>
                  <Item.Header as="a">
                    <h1>{subscriptionNumber}</h1>
                  </Item.Header>
                  <RecurringStatus value={status} />
                  <Item.Extra>
                    <Breadcrumb size="large">
                      <Breadcrumb.Section>
                        <Link to={config.DEFAULT_RECURRING_URL}>Recurring</Link>
                      </Breadcrumb.Section>
                      <Breadcrumb.Divider icon="right angle" />
                      <Breadcrumb.Section active>
                        {subscriptionNumber}
                      </Breadcrumb.Section>
                    </Breadcrumb>
                  </Item.Extra>
                </Item.Content>
              </Item>
            </Item.Group>
          </GridColumn>
        </GridRow>
        <GridRow className="margin-top-30 label-container word-wrap">
          <GridColumn width={5}>
            <Header as="h4">
              <Translate value="recurring.recurring-detail.subscription-number" />
            </Header>
            <span className="label">{subscriptionNumber}</span>
          </GridColumn>
          <GridColumn width={5}>
            <Header as="h4">
              <Translate value="recurring.recurring-detail.date" />
            </Header>
            <span className="label">{dateTime}</span>
          </GridColumn>
          <GridColumn width={5}>
            <Header as="h4">
              <Translate value="recurring.recurring-detail.frequency" />
            </Header>
            <span className="label capitalize">{amount}</span>
          </GridColumn>
        </GridRow>
        <GridRow className="label-container word-wrap">
          <GridColumn width={5}>
            <Header as="h4">
              <Translate value="recurring.recurring-detail.full-name" />
            </Header>
            <span className="label">{accountName}</span>
          </GridColumn>
          <GridColumn width={5}>
            <Header as="h4">
              <Translate value="recurring.recurring-detail.email" />
            </Header>
            <span className="label">{accountEmail}</span>
          </GridColumn>
          <GridColumn width={5}>
            <Header as="h4">
              <Translate value="recurring.recurring-detail.amount" />
            </Header>
            <span className="label">{raisedAmount}</span>
          </GridColumn>
        </GridRow>
        {(accountPhone || accountAddress) && (
          <GridRow className="label-container word-wrap">
            {accountPhone && (
              <GridColumn width={5}>
                <Header as="h4">
                  <Translate value="recurring.recurring-detail.phone" />
                </Header>
                <span className="label">{accountPhone}</span>
              </GridColumn>
            )}
            {accountAddress && (
              <GridColumn width={10}>
                <Header as="h4">
                  <Translate value="recurring.recurring-detail.address" />
                </Header>
                <span className="label">{accountAddress}</span>
              </GridColumn>
            )}
          </GridRow>
        )}
        <GridRow className="label-container word-wrap">
          <GridColumn width={5}>
            <Header as="h4">
              <Translate value="recurring.recurring-detail.next-payment-date" />
            </Header>
            <span className="label">{nextPaymentDate}</span>
          </GridColumn>
          {showBehaviourSettings && (
            <GridColumn width={10}>
              <Header as="h4">
                <Translate value="recurring.recurring-detail.behaviour.title" />
              </Header>
              <span className="label">
                <ReceiptBehaviour
                  campaign={recurring?.campaign}
                  settingsType={recurring?.settings?.type}
                  settingsReceipt={recurring?.settings?.receipt}
                />
              </span>
            </GridColumn>
          )}
        </GridRow>
        {managementUrl && (
          <GridRow className="label-container word-wrap">
            <GridColumn width={16}>
              <Header as="h4">
                <Translate value="recurring.recurring-detail.managementUrl" />
              </Header>
              <span className="label">
                <a href={managementUrl} target="_blank" rel="noreferrer">
                  {managementUrl}
                </a>
              </span>
            </GridColumn>
          </GridRow>
        )}
        {!isStatusWarningMessageVisible && donationCountLimit && (
          <GridRow className="label-container word-wrap">
            <GridColumn width={12}>
              {donationCount >= donationCountLimit ? (
                <Header as="h4">
                  {I18n.t('recurring.recurring-detail.limit.stopped')}
                </Header>
              ) : (
                <Header as="h4">
                  {I18n.t('recurring.recurring-detail.limit.active', {
                    limit: donationCountLimit - donationCount
                  })}
                </Header>
              )}
            </GridColumn>
          </GridRow>
        )}
        <GridRow>
          <GridColumn>
            <div className="view-table">
              <div className="view-table-content" style={{
                backgroundColor: "#fff"
              }}>
                <ViewIconItem
                  type={pageTypesStrings.CAMPAIGN}
                  data={this.getCampaign()}
                  imageUrl={this.getCampaignImage()}
                />
              </div>
            </div>
          </GridColumn>
        </GridRow>
      </Grid>
    );
  }
}

const mapState = ({ recurring }) => {
  return {
    recurringDetail: recurring.record.data.data
  };
};

const mapDispatch = (dispatch) => {
  return {};
};

const RecurringDonationDetailHeaderContainer = withRouter(
  connect(mapState, mapDispatch)(DetailHeader)
);
export default RecurringDonationDetailHeaderContainer;
