import React from 'react';
import NumberFormat from 'react-number-format';

interface CurrencyFormatterProps {
  value: number;
  truncate?: boolean;
  fixed?: number;
}

const CurrencyFormatter = ({
  value,
  truncate,
  fixed = 2
}: CurrencyFormatterProps) => {
  let formattedValue: number | string = '';

  if (truncate) {
    formattedValue = Math.floor(Math.round(value * 100) / 100);
  } else {
    const strValue = (Math.round(value * 100) / 100).toString();
    formattedValue = parseFloat(strValue).toFixed(fixed);
  }

  return (
    <div className="currency-formatter">
      <NumberFormat
        value={formattedValue}
        displayType={'text'}
        thousandSeparator
        prefix={'$'}
      />
    </div>
  );
};

export default CurrencyFormatter;
