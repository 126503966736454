import React from 'react';
import { Translate } from 'react-redux-i18n';

import { TemplatePage } from '../../../../components/common/pager';
import { TemplateHeader } from '../../../../components/template/templateHeader';
import { TemplateInput } from '../../../../components/template/templateInput';
import { TemplateFormBuilderAnswers } from '../../../../components/template/templateFormBuilderAnswers';

import { PartOneInfo } from '../components/partOneInfo';
import Button from '../../../../components/common/button';

import { paths } from '../constants/template';

export const getPartOneEditTab = (props) => {
  const {
    onChange,
    required,
    email,
    formBuilderAnswers,
    dateReceived,
    fundraiserRaisedAmount,
    fundraiserUrlFull,
    eventName,
    eventAmbassadorProgramName,
    onApplyClick,
    isApplicationPartTwoVisible
  } = props;

  return (
    <TemplatePage
      id="part-one"
      i18nKey={`${
        isApplicationPartTwoVisible
          ? 'applications.template.page.partOne.application-details-title'
          : 'applications.template.page.partOne.title'
      }`}
    >
      <TemplateHeader
        as="h2"
        label="applications.template.page.partOne.header-name.label"
      />

      <PartOneInfo
        dateReceived={dateReceived}
        fundraiserRaisedAmount={fundraiserRaisedAmount}
        fundraiserUrlFull={fundraiserUrlFull}
        eventName={eventName}
        eventAmbassadorProgramName={eventAmbassadorProgramName}
      />

      <TemplateHeader
        as="h5"
        lfalseabel="applications.template.page.partOne.account-details-name.label"
      />

      <TemplateInput
        id="partOneFirstName"
        path={paths.FIRST_NAME}
        label="applications.template.page.partOne.first-name.label"
        onChange={onChange}
        validation={required}
      />

      <TemplateInput
        id="partOneLastName"
        path={paths.LAST_NAME}
        label="applications.template.page.partOne.last-name.label"
        onChange={onChange}
        validation={required}
      />

      <TemplateInput
        id="partOneEmail"
        path={paths.EMAIL}
        label="applications.template.page.partOne.email-name.label"
        onChange={onChange}
        validation={email}
      />

      <TemplateHeader
        as="h5"
        label="applications.template.page.partOne.event-questions-name.label"
      />

      <TemplateFormBuilderAnswers
        id="templateFormBuilderAnswers"
        path={paths.APPLICATION_ENTRY}
        disableTargetField
        onChange={onChange}
        validation={formBuilderAnswers}
      />

      <TemplateHeader
        as="h5"
        path={paths.OGRANIZATION_QUESTIONS_HEADER}
        label="applications.template.page.partOne.organization-questions-name.label"
      />

      <TemplateFormBuilderAnswers
        id="templateFormBuilderAnswers"
        path={paths.APPLICATION_ADDITIONAL}
        disableTargetField
        onChange={onChange}
        validation={formBuilderAnswers}
      />

      <TemplateHeader
        as="h2"
        path={paths.PART_TWO_HEADER}
        label="applications.template.page.partOne.part-two-header.label"
      />

      <TemplateFormBuilderAnswers
        id="templateFormBuilderAnswers"
        path={paths.APPLICATION_CONFIRMATION}
        disableTargetField
        onChange={onChange}
        validation={formBuilderAnswers}
      />

      <div path={paths.APPLY_BUTTON} className="template-button-container">
        <Button className="apply-button" onClick={onApplyClick}>
          <Translate value="applications.template.page.partOne.apply-button-name.label" />
        </Button>
        <div className="template-button-subtext">
          <Translate value="applications.template.page.partOne.application-information-name.label" />
        </div>
      </div>
    </TemplatePage>
  );
};
