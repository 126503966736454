import React from 'react';

import { formatDecimalNumber } from '../../../helpers/converterHelper';

import { ViewTable } from '../../../components/layouts/viewTable';
import { ViewSectionRow } from '../../../components/layouts/viewSectionRow';
import { DonationPaymentSectionRow } from './components/donationPaymentSectionRow';

import {
  ONLINE_DONATION_TYPE,
  PENDING_DONATION_TYPE
} from '../../campaign/constants';
import {
  donationI18Keys,
  getPaymentMethodValue
} from './constants/donationSettings';

export const DonationDetails = (props) => {
  const { donor, amount, financials, type, message } = props.donation;
  const { isShowEmptyFields } = props;

  const firstName = donor?.firstName;
  const lastName = donor?.lastName;
  const email = donor?.email;
  const net = financials?.feeDetail?.net;
  const totalFees = financials && amount - net;

  const isOnlineOrPending =
    type === ONLINE_DONATION_TYPE || type === PENDING_DONATION_TYPE;

  const amountString = `$${formatDecimalNumber(amount, true)}`;
  const totalFeesString = `$${formatDecimalNumber(totalFees, true)}`;

  const creditCardTitle = getPaymentMethodValue(financials?.paymentMethod);

  return (
    <React.Fragment>
      <div className="donation-details-section">
        <ViewTable title="Donation Details">
          <ViewSectionRow
            isShowEmptyFields={isShowEmptyFields}
            title={`${donationI18Keys.donationDetailsI18Key}.first-name`}
            data={firstName}
          />
          <ViewSectionRow
            isShowEmptyFields={isShowEmptyFields}
            title={`${donationI18Keys.donationDetailsI18Key}.last-name`}
            data={lastName}
          />
          <ViewSectionRow
            isShowEmptyFields={isShowEmptyFields}
            title={`${donationI18Keys.donationDetailsI18Key}.email`}
            data={email}
          />
          <ViewSectionRow
            isShowEmptyFields={isShowEmptyFields}
            title={`${donationI18Keys.donationDetailsI18Key}.support-message`}
            data={message}
          />
          <ViewSectionRow
            title={`${donationI18Keys.donationDetailsI18Key}.amount`}
            data={amountString}
          />
          {isOnlineOrPending && (
            <React.Fragment>
              <ViewSectionRow
                title={`${donationI18Keys.donationDetailsI18Key}.total-fees`}
                data={totalFeesString}
              />
              <DonationPaymentSectionRow
                title={creditCardTitle}
                data={financials}
              />
            </React.Fragment>
          )}
        </ViewTable>
      </div>
    </React.Fragment>
  );
};
