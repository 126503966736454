import {
  generalFormBuilderPath,
  customFieldCategory
} from '../../constants/formBuilder';

export const templateConstants = {
  DETAIL_PAGE: 'detailPage',
  DONATION_DETAILS: 'donationDetails'
};

export const paths = {
  FUNDRAISER_ID: 'fundraiserId',
  TEAM_ID: 'teamId',
  COLLECTION_ID: 'collectionId',
  PAGE_ID: 'pageId',
  PAGE_TYPE: 'pageType',
  PAGE: 'page',
  DONATION_FORMBUILDER: `${generalFormBuilderPath}.${customFieldCategory.DONATION}`
};

export const pageTypes = {
  FUNDRAISER: 'fundraiser',
  TEAM: 'team',
  COLLECTION: 'collection'
};

export const donationQuestionFormIds = {
  DONOR_FIRST_NAME: 'donorFirstName',
  DONOR_LAST_NAME: 'donorLastName',
  DONOR_BUSINESS_NAME: 'donorBusinessName',
  EMAIL: 'email'
};

export const availableFiltersKeys = [
  'campaignId',
  'teamId',
  'fundraiserId',
  'donatedAtLocal',
  'amount',
  'type',
  'financials.refunded',
  'paymentPlatform.transferId'
];

export const displayNameKeys = {
  asFirstName: 0,
  asFullName: 1,
  asAnonymous: 2,
  asCustomName: 3
};

export const displayNameOptions = [
  {
    key: displayNameKeys.asFirstName,
    translation: 'firstName',
    value: displayNameKeys.asFirstName,
    isAnonymous: false
  },
  {
    key: displayNameKeys.asFullName,
    displayName: 'fullName',
    translation: 'fullName',
    value: displayNameKeys.asFullName,
    isAnonymous: false
  },
  {
    key: displayNameKeys.asCustomName,
    translation: 'customName',
    value: displayNameKeys.asCustomName,
    isAnonymous: false
  },
  {
    key: displayNameKeys.asAnonymous,
    translation: 'anonymous',
    value: displayNameKeys.asAnonymous,
    isAnonymous: true
  }
];
