import React from 'react';
import { I18n } from 'react-redux-i18n';
import { ColouredText } from '../../../components/common/colouredText';
import { formatDecimalNumber } from '../../../helpers/converterHelper';
import { saleTypeSettings } from '../constants';
import { OFFLINE_SALE_TYPE } from '../../campaign/constants';

interface ITicketingStatusProps {
  ticketData: any;
}

export const TicketingStatus = ({ ticketData }: ITicketingStatusProps) => {
  const amountString = `$${formatDecimalNumber(ticketData?.amount, true)}`;
  const financials = ticketData?.financials || {};
  const type = ticketData.type;
  const isOffline = type == OFFLINE_SALE_TYPE;

  const { returned: cancelled, refunded, partiallyRefunded } = financials;

  const nonSuccessfulStatus = cancelled || refunded || partiallyRefunded;

  return (
    <div>
      <div>{I18n.t('ticketing.edit.titles.header')}</div>
      <div className="sale-header">
        <h1>{`${financials?.currency}${amountString}`}</h1>
        <div className="sale-status-container">
          {!nonSuccessfulStatus && (
            <ColouredText
              className="sale-type"
              backgroundColor={
                saleTypeSettings.SUCCESS.backgroundColor
              }
              title={saleTypeSettings.SUCCESS.title}
            />
          )}
          {isOffline && (
            <ColouredText
              className="sale-type"
              backgroundColor={
                saleTypeSettings.OFFLINE.backgroundColor
              }
              title={saleTypeSettings.OFFLINE.title}
            />
          )}
          {cancelled && (
            <ColouredText
              className="sale-type"
              backgroundColor={saleTypeSettings.CANCELLED.backgroundColor}
              title={saleTypeSettings.CANCELLED.title}
            />
          )}
          {refunded && (
            <ColouredText
              className="sale-type"
              backgroundColor={saleTypeSettings.REFUNDED.backgroundColor}
              title={saleTypeSettings.REFUNDED.title}
            />
          )}
          {partiallyRefunded && (
            <ColouredText
              className="sale-type"
              backgroundColor={
                saleTypeSettings.PARTIALLY_REFUNDED.backgroundColor
              }
              title={saleTypeSettings.PARTIALLY_REFUNDED.title}
            />
          )}
        </div>
      </div>
    </div>
  );
};
