import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import get from 'lodash.get';

import * as ticketingActions from './store/ticketingActions';
import { setRedirectUrl } from '../../modules/session';
import { hideCondition } from '../../helpers/hideHelper';

import { Menu } from 'semantic-ui-react';
import { Common } from '../../components';
import BasePage from '../../components/common/basePageView';
import ContextMenu from '../../components/common/grid/contextMenu';
import Panel from '../../components/common/grid/managed/panel';
import clear from '../../components/clear';
import Spinner from '../../components/common/spinner';
import RefundSaleWindowContainer from './modals/refundSaleWindow/RefundSaleWindow';
import RefundTicketWindowContainer from './modals/refundTicketWindow';
import ReceiptSaleWindowContainer from './modals/receiptSaleWindow';
import ReceiptTicketWindowContainer from './modals/receiptTicketWindow';

import RecordResultMessage from '../../components/common/recordResult';
import { Record } from '../../modules/bin/utility';
import { CONTEXT_MENU_KEYS } from '../../constants/contextMenuEntries';
import { ExportDownloadProvider } from '../export-history';
import { ExportDataButton } from '../../components/template/templateExportButton';
import { gridKeys } from '../../constants/gridKeys';
import { gridTabs, i18nKeys } from './constants';
import { getListWithOrgFilterIfSysAdmin } from '../../helpers/listHelper';
import { OBJECT_ORGANIZATION_ID_FILTER } from '../../constants/metadataConstants';
import { filterLookupBaseOptions } from './metadataConstants';

class SaleList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      exportModalValues: null
    };
  }

  componentDidMount() {
    if (this.props.orgId) this.props.getOrgRecord(this.props.orgId);
  }

  isChooseTabButtonDisabled = () => {
    return (
      Record.isRecordLoading(this.props.salesList) ||
      Record.isRecordLoading(this.props.ticketsList) ||
      Record.isRecordLoading(this.props.merchandiseList)
    );
  };

  handleMenuItemClick = (e, { name }) => {
    if (name === gridTabs.SALES) this.props.history.push(`/sales`);
    else this.props.history.push(`/sales/${name}`);
  };

  getTabsButtons = () => {
    const isChooseTabButtonDisabled = this.isChooseTabButtonDisabled();

    return (
      <Menu
        className="accounts-tab-buttons-group"
        style={{ margin: '0px', borderRight: '0px' }}
      >
        <Menu.Item
          name={gridTabs.SALES}
          active={
            this.props.match.params.tab === gridTabs.SALES ||
            !this.props.match.params.tab
          }
          disabled={isChooseTabButtonDisabled}
          onClick={this.handleMenuItemClick}
        >
          <Translate value={i18nKeys.SALES_TAB_TITLE} />
        </Menu.Item>
        <Menu.Item
          name={gridTabs.TICKETS}
          active={this.props.match.params.tab === gridTabs.TICKETS}
          disabled={isChooseTabButtonDisabled}
          onClick={this.handleMenuItemClick}
        >
          <Translate value={i18nKeys.TICKETS_TAB_TITLE} />
        </Menu.Item>
        <Menu.Item
          name={gridTabs.MERCHANDISE}
          active={this.props.match.params.tab === gridTabs.MERCHANDISE}
          disabled={isChooseTabButtonDisabled}
          onClick={this.handleMenuItemClick}
        >
          <Translate value={i18nKeys.MERCHANDISE_TAB_TITLE} />
        </Menu.Item>
      </Menu>
    );
  };

  onRefundSaleContextClick = (id, item) => {
    this.props.ticketingActions.showRefundConfirmation(item, 'sale');
  };

  onRefundTicketContextClick = (id, item) => {
    this.props.ticketingActions.showRefundConfirmation(item, 'ticket');
  };

  onRefundMerchandiseContextClick = (id, item) => {
    this.props.ticketingActions.showRefundConfirmation(item, 'merchandise');
  };

  onReissueSaleContextClick = (id, item) => {
    if (get(item, 'customer.email')) {
      this.props.ticketingActions.showReceiptConfirmation(item, 'sale');
    } else {
      this.props.ticketingActions.getReceiptMessageError('saveRecord');
    }
  };

  onReissueTicketContextClick = (id, item) => {
    if (get(item, 'customer.email')) {
      this.props.ticketingActions.showReceiptConfirmation(item, 'ticket');
    } else {
      this.props.ticketingActions.getReceiptMessageError('saveRecord');
    }
  };

  isReissueHidden = (item) => {
    return get(item, 'financials.refunded');
  };

  clearMessages = () => {
    this.props.ticketingActions.clearRecordMessages('saveRecord');
  };

  onAddOfflineSale = () => {
    this.props.history.push(`/sales/create/invoice-information`);
  };

  hideColumnCondition = (item) => {
    return hideCondition(
      this.props.isOrganizationAdmin,
      item,
      'path',
      'organization.name'
    );
  };

  render() {
    if (
      Record.isRecordLoading(this.props.exportFileRecord) ||
      Record.isRecordLoading(this.props.impersonatedRecord)
    ) {
      return <Spinner />;
    }

    const { Grid } = Common;
    const tabsButtons = this.getTabsButtons();

    const additionalTicketFilters = [
      { key: 'enabled', operator: 'EqualTo', value: true }
    ];
    const additionalMerchandiseFilters = [
      { key: 'enabled', operator: 'EqualTo', value: true }
    ];

    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.saveRecord}
          onDismiss={this.clearMessages}
        />

        <RefundSaleWindowContainer />
        <RefundTicketWindowContainer />
        <RefundTicketWindowContainer path="merchandise" />
        <ReceiptSaleWindowContainer />
        <ReceiptTicketWindowContainer />
        <div>
          <div className="grid-top-block">
            <h1>
              <Translate value="sale.title" />
            </h1>
          </div>
          {tabsButtons}
          {(this.props.match.params.tab === gridTabs.SALES ||
            !this.props.match.params.tab) && (
            <Grid.Managed
              id="salesList"
              listKey={this.props.salesListKey}
              list={getListWithOrgFilterIfSysAdmin(
                this.props.salesList,
                this.props.isSystemAdmin
              )}
              actions={this.props.saleListActions}
              search
              advancedFilterButton
              searchVersion="v3"
              toggleColumns
              filtersButton
              hideColumn={this.hideColumnCondition}
              activeTabFilter={this.props.match.params.tab}
            >
              {this.props.isExportDataAllowed && (
                <Panel name="right">
                  <ExportDownloadProvider entity="sale">
                    {({ open }) => (
                      <ExportDataButton
                        versions={['v3']}
                        onExportClick={() => {
                          open(this.props.salesList.request);
                        }}
                      />
                    )}
                  </ExportDownloadProvider>
                  <Common.Button
                    id="add-offline-sale"
                    onClick={this.onAddOfflineSale}
                  >
                    <Translate value="campaign.list.view.add-offline-sale" />
                  </Common.Button>
                </Panel>
              )}
              <ContextMenu>
                <ContextMenu.Item
                  key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.VIEW_SALE.key}
                  label={I18n.t('sale.list.context.view-sale')}
                  contextMenuOptions={
                    CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.VIEW_SALE
                  }
                  onClick={() =>
                    this.props.setRedirectUrl(
                      this.props.history.location.pathname
                    )
                  }
                />
                <ContextMenu.Item
                  key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.EDIT_SALE.key}
                  label={I18n.t('sale.list.context.edit-sale')}
                  contextMenuOptions={
                    CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.EDIT_SALE
                  }
                  onClick={() =>
                    this.props.setRedirectUrl(
                      this.props.history.location.pathname
                    )
                  }
                />
                {/* TODO: add me back in once confirmed it's needed
                <ContextMenu.Item
                  key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.REFUND_SALE.key}
                  label={I18n.t('sale.list.context.refund-sale')}
                  onClick={this.onRefundSaleContextClick}
                /> \*/}
                <ContextMenu.Item
                  key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.REISSUE_INVOICE.key}
                  label={I18n.t('sale.list.context.reissue-invoice')}
                  onClick={this.onReissueSaleContextClick}
                />
              </ContextMenu>
            </Grid.Managed>
          )}
          {this.props.match.params.tab === gridTabs.TICKETS && (
            <Grid.Managed
              id="ticketsList"
              listKey={this.props.ticketsListKey}
              list={getListWithOrgFilterIfSysAdmin(
                this.props.ticketsList,
                this.props.isSystemAdmin,
                OBJECT_ORGANIZATION_ID_FILTER
              )}
              actions={this.props.tikcetListActions}
              search
              advancedFilterButton
              searchVersion="v3"
              toggleColumns
              filtersButton
              hideColumn={this.hideColumnCondition}
              additionalFilters={additionalTicketFilters}
              filterLookupBaseOptions={filterLookupBaseOptions}
            >
              {this.props.isExportDataAllowed && (
                <Panel name="right">
                  <ExportDownloadProvider entity="saleDetail">
                    {({ open }) => (
                      <ExportDataButton
                        versions={['v2', 'v3']}
                        onExportClick={(version) => {
                          let additionalData = {};
                          if (version === 'v3') {
                            additionalData.layout = 'Extended';
                          }
                          open({
                            ...additionalData,
                            ...(this.props.ticketsList.request || {})
                          });
                        }}
                      />
                    )}
                  </ExportDownloadProvider>
                </Panel>
              )}

              <ContextMenu>
                <ContextMenu.Item
                  key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.EDIT_TICKET.key}
                  label={I18n.t('ticket.list.context.edit-ticket')}
                  contextMenuOptions={
                    CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.EDIT_TICKET
                  }
                  onClick={() =>
                    this.props.setRedirectUrl(
                      this.props.history.location.pathname
                    )
                  }
                />
                <ContextMenu.Item
                  key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.REFUND_TICKET.key}
                  label={I18n.t('ticket.list.context.refund-ticket')}
                  onClick={this.onRefundTicketContextClick}
                />
                <ContextMenu.Item
                  isHiden={this.isReissueHidden}
                  key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.REISSUE_TICKET.key}
                  label={I18n.t('ticket.list.context.reissue-ticket')}
                  onClick={this.onReissueTicketContextClick}
                />
              </ContextMenu>
            </Grid.Managed>
          )}
          {this.props.match.params.tab === gridTabs.MERCHANDISE && (
            <Grid.Managed
              id="merchandiseList"
              listKey={this.props.merchandiseListKey}
              list={getListWithOrgFilterIfSysAdmin(
                this.props.merchandiseList,
                this.props.isSystemAdmin,
                OBJECT_ORGANIZATION_ID_FILTER
              )}
              actions={this.props.merchandiseListActions}
              search
              advancedFilterButton
              searchVersion="v3"
              toggleColumns
              filtersButton
              hideColumn={this.hideColumnCondition}
              additionalFilters={additionalMerchandiseFilters}
              filterLookupBaseOptions={filterLookupBaseOptions}
            >
              {this.props.isExportDataAllowed && (
                <Panel name="right">
                  <ExportDownloadProvider entity="saleDetail">
                    {({ open }) => (
                      <ExportDataButton
                        versions={['v3']}
                        onExportClick={() => {
                          open(this.props.merchandiseList.request);
                        }}
                      />
                    )}
                  </ExportDownloadProvider>
                </Panel>
              )}
              <ContextMenu>
                <ContextMenu.Item
                  key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.EDIT_TICKET.key}
                  label={I18n.t('ticket.list.context.edit-ticket')}
                  contextMenuOptions={
                    CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.EDIT_TICKET
                  }
                  onClick={() =>
                    this.props.setRedirectUrl(
                      this.props.history.location.pathname
                    )
                  }
                />
                <ContextMenu.Item
                  key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.REFUND_TICKET.key}
                  label={I18n.t('ticket.list.context.refund-ticket')}
                  onClick={this.onRefundMerchandiseContextClick}
                />
              </ContextMenu>
            </Grid.Managed>
          )}
        </div>
      </BasePage>
    );
  }
}

const mapState = ({ ticketing, account, session }) => {
  const asyncExportEnabled =
    get(
      ticketing,
      'asyncExport.data.data.template.value.beta.asyncExports',
      false
    ) && session?.isOrganizationAdmin;
  const exportFileRecord = ticketing.exportFileRecord;
  const saveRecord = ticketing.saveRecord;
  const impersonatedRecord = account.impersonatedRecord;

  const salesGrid = Common.Grid.Managed.mapGridState(ticketing, gridKeys.SALES);
  const ticketsGrid = Common.Grid.Managed.mapGridState(
    ticketing,
    gridKeys.TICKETS
  );
  const merchandiseGrid = Common.Grid.Managed.mapGridState(
    ticketing,
    gridKeys.MERCHANDISE
  );

  return {
    orgId: session?.organization?.id,
    asyncExportEnabled,

    exportFileRecord,
    saveRecord,
    impersonatedRecord,

    // The list data for sales
    salesListKey: salesGrid.key,
    salesTimestamp: salesGrid.timestamp,
    salesList: salesGrid.list,

    // The list data for sales
    ticketsListKey: ticketsGrid.key,
    ticketsTimestamp: ticketsGrid.timestamp,
    ticketsList: ticketsGrid.list,

    // The merchandise list data for sales
    merchandiseListKey: merchandiseGrid.key,
    merchandiseTimestamp: merchandiseGrid.timestamp,
    merchandiseList: merchandiseGrid.list,

    isSystemAdmin: get(session, 'isSystemAdmin'),
    isOrganizationAdmin: get(session, 'isOrganizationAdmin'),
    isExportDataAllowed: get(session, 'permissions.exportData') === 'allow'
  };
};

const mapDispatch = (dispatch) => {
  const dispatchers = bindActionCreators(ticketingActions, dispatch);

  // Maps the required functions for the managed list to a prop.
  dispatchers.saleListActions = Common.Grid.Managed.bindGridActions(dispatch, {
    getMetadata: ticketingActions.getSaleMetadata,
    getListData: ticketingActions.getSaleListData,
    getListDataBySearchTerm: ticketingActions.getSaleListDataBySearchTerm,
    toggleColumnsChange: ticketingActions.toggleColumnsChange
  });

  // Maps the required functions for the managed list to a prop.
  dispatchers.tikcetListActions = Common.Grid.Managed.bindGridActions(
    dispatch,
    {
      getMetadata: ticketingActions.getTicketMetadata,
      getListData: ticketingActions.getTicketListData,
      getListDataBySearchTerm: ticketingActions.getTicketListDataBySearchTerm,
      toggleColumnsChange: ticketingActions.toggleColumnsChange
    }
  );

  // Maps the required functions for the managed list to a prop.
  dispatchers.merchandiseListActions = Common.Grid.Managed.bindGridActions(
    dispatch,
    {
      getMetadata: ticketingActions.getMerchandiseMetadata,
      getListData: ticketingActions.getMerchandiseListData,
      getListDataBySearchTerm:
        ticketingActions.getMerchandiseListDataBySearchTerm,
      toggleColumnsChange: ticketingActions.toggleColumnsChange
    }
  );

  dispatchers.ticketingActions = bindActionCreators(ticketingActions, dispatch);
  dispatchers.setRedirectUrl = bindActionCreators(setRedirectUrl, dispatch);

  return dispatchers;
};

const SalesListContainer = clear(connect(mapState, mapDispatch)(SaleList));
export default SalesListContainer;
