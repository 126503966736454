import React, { useMemo } from 'react';
import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';
import { Loader } from 'semantic-ui-react';

import { stripeName } from '../../../../pages/organization/constants';

// TODO: the data source can come from either organization or campaings, both data sets have different paymentPlatorms
// i.e. one inclues paymentPlatorms as a list, the other is an object with main and alternative paymentPlatorm list
// update the below to only include campaigns data set (main/alternative) when donations are converted over
export const DonationFinancialsPlatformSectionRow = (props) => {
  const {
    title,
    paymentPlatforms,
    alternativePaymentPlatforms,
    data,
    isSystemAdmin,
    getPaymentPlatforms,
    isPaymentPlatformsLoaded
  } = props;

  const platform = get(data, 'platform');
  const chargeKey = get(data, 'platformDetails.chargeKey');
  const intentKey = get(data, 'platformDetails.intentKey');
  const paymentKey = intentKey || chargeKey;

  const accountKey = useMemo(() => {
    const platform =
      alternativePaymentPlatforms?.find(
        (p) => p.platform === stripeName && p.purpose?.toLowerCase() === 'sale'
      ) || paymentPlatforms?.find((p) => p.platform === stripeName);
    return platform?.accountIdentifier || platform?.accountKey;
  }, [alternativePaymentPlatforms, paymentPlatforms, stripeName]);

  if (isSystemAdmin && paymentKey && !isPaymentPlatformsLoaded) {
    getPaymentPlatforms();
  }

  return (
    <React.Fragment>
      <div className="view-details-content-row column">
        <h4>{I18n.t(title)}</h4>
        <div className="column-content">
          <span style={{ textTransform: 'capitalize' }}>{platform}</span>
          {paymentKey && (
            <React.Fragment>
              {!accountKey && isPaymentPlatformsLoaded && (
                <Loader active inline size="tiny" />
              )}
              {isSystemAdmin && accountKey && isPaymentPlatformsLoaded && (
                <a
                  href={`https://dashboard.stripe.com/${accountKey}/payments/${paymentKey}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View in Stripe
                </a>
              )}
              {!isSystemAdmin && (
                <a
                  href={`https://dashboard.stripe.com/payments/${paymentKey}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View in Stripe
                </a>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
