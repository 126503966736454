import React from 'react';
import { I18n } from 'react-redux-i18n';
import { TemplatePage } from '../../../components/common/pager';
import { TemplateInput } from '../../../components/template/templateInput';
import { TemplateDatePicker } from '../../../components/template/templateDatePicker';
import { paths } from '../constants/template';
import { TemplateAutocomplete } from '../../../components/template/templateAutocomplete';
import { TemplateCurrency } from '../../../components/template/templateCurrency';
import { entityTypes, imageTypes } from '../../../constants/imageUpload';
import { TemplateAbout } from '../../../components/template/templateAbout';
import { imageFormats } from '../../../constants/imageUpload';
import TemplateEditableUploadContainer from '../../../components/template/templateEditableUpload';
import { TemplateColorPicker } from '../../../components/template/templateColorPicker';
import { TemplateCheckbox } from '../../../components/template/templateCheckbox';
import { Form } from 'semantic-ui-react';
import config from '../../../constants/index';
import { TemplateDropdown } from '../../../components/template/templateDropdown';
import { invertionTextColourOptions } from '../../campaign/constants';
import { TemplateCustomVideoSelector } from '../../../components/template/custom/templateCustomVideoSelector';
import { TemplateHorizontalRule } from '../../../components/template/templateHorizontalRule';
import { TemplateHeader } from '../../../components/template/templateHeader';
import { TemplateTimePicker } from '../../../components/template/templateTimePicker';
import { campaignContract } from '../../campaign/create/templates';
import { TemplateAddressAutocomplete } from '../../../components/template/templateAddressAutocomplete';

export const getSetupTab = (props) => {
  const {
    onChange,
    onCityAutoCompleteChange,
    getOrganizationRequest,
    getOrganizationItem,
    onOrganizationChange,
    onEventOrganizerRequest,
    onEventOrganizerChange,
    getEventOrganizerItem,
    onAllowTimeAndPlaceChange,
    getTimeZoneOptions,
    getChildValidation,
    targetValidation,
    required,
    timeZone,
    nameValidation,
    subdomainValidation,
    eventAboutValidation,
    campaignAboutValidation,
    urlValidation,
    timeDetailsValidation,
    locationValidation
  } = props;

  return (
    <TemplatePage id="setup" i18nKey="event.template.page.setup.title">
      <TemplateInput
        id="setupEventName"
        path={paths.NAME}
        label="event.template.page.setup.event-name.label"
        onChange={onChange}
        validation={nameValidation}
      />

      <TemplateInput
        id="setupEventSubdomain"
        path={paths.URL_PATH}
        label="event.template.page.setup.event-subdomain.label"
        onChange={onChange}
        validation={subdomainValidation}
      />

      <TemplateAutocomplete
        id="setupEventOrganization"
        entity="organization"
        path={paths.ORGANIZATION_ID}
        selected="organization"
        label="event.template.page.setup.event-organisation.label"
        onChange={onOrganizationChange}
        request={getOrganizationRequest}
        getItem={getOrganizationItem}
        validation={required}
      />

      <TemplateAutocomplete
        id="setupEventOrganizer"
        entity="EventOrganizer"
        path={paths.EVENT_ORGANIZER_ID}
        selected="eventOrganizer"
        label="event.template.page.setup.event-organiser.label"
        onChange={onEventOrganizerChange}
        request={onEventOrganizerRequest}
        getItem={getEventOrganizerItem}
      />

      <TemplateDropdown
        id="setupTimeZone"
        path={paths.TIMEZONE}
        search
        label="event.template.page.setup.timezone.label"
        subtext="event.template.page.setup.timezone.subtext"
        validation={timeZone}
        onChange={onChange}
        getOptions={getTimeZoneOptions}
      />

      <TemplateCurrency
        id="setupEventOrganiserFee"
        path={paths.ORGANIZATION_FEE}
        inputLabel={'%'}
        maxValue={config.MAX_PERCENT_VALUE}
        allowDecimal
        label="event.template.page.setup.event-organization-fee.label"
        onChange={onChange}
      />

      <TemplateCurrency
        id="setupEventTargetAmount"
        path={paths.FUNDRAISING_EVENT_TARGET}
        label="event.template.page.setup.event-target.label"
        validation={targetValidation}
        onChange={onChange}
      />

      <TemplateAbout
        id="setupEventAbout"
        path={paths.EVENT_ABOUT}
        label="event.template.page.setup.event-about.label"
        onChange={onChange}
        validation={eventAboutValidation}
        entity={entityTypes.EVENT}
      />

      <TemplateInput
        id="setupEventTagline"
        path={paths.TAGLINE}
        label="event.template.page.setup.event-tagline.label"
        onChange={onChange}
      />

      <TemplateInput
        id="setupEventMarketingWebsite"
        path={paths.MARKETING_WEBSITE}
        validation={urlValidation}
        label="event.template.page.setup.marketing-website.label"
        onChange={onChange}
      />

      <TemplateEditableUploadContainer
        id="setupEventHeroImage"
        path={paths.HERO_IMAGE}
        entity={entityTypes.EVENT}
        position="top"
        height="350"
        width="1500"
        format={imageFormats}
        uploadType={imageTypes.HERO_IMAGE}
        label="event.template.page.setup.event-hero-image.label"
        onChange={onChange}
        validation={required}
      />

      <TemplateCustomVideoSelector
        id="heroVideo"
        label={I18n.t('event.template.page.setup.event-hero-video.label')}
        path={paths.HERO_VIDEO}
        onChange={onChange}
        validation={{ required: false }}
      />

      <TemplateEditableUploadContainer
        id="setupEventPhoto"
        path={paths.DISPLAY_IMAGE}
        entity={entityTypes.EVENT}
        position="left"
        height="350"
        width="350"
        format={imageFormats}
        uploadType={imageTypes.LOGO}
        label="event.template.page.setup.event-display-image.label"
        onChange={onChange}
        validation={required}
      />

      <Form.Group widths="equal">
        <TemplateColorPicker
          id="setupEventPrimaryColour"
          path={paths.PRIMARY_COLOR}
          label="event.template.page.setup.event-primary-color.label"
          onChange={onChange}
        />

        <TemplateColorPicker
          id="setupEventSecondaryColour"
          path={paths.SECONDARY_COLOR}
          label="event.template.page.setup.event-secondary-color.label"
          onChange={onChange}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <TemplateDropdown
          id="setupInvertPrimaryTextColor"
          path={paths.INVERT_PRIMARY_COLOR}
          label="event.template.page.setup.invert-colors.invertPrimaryTextColor.label"
          onChange={onChange}
          options={invertionTextColourOptions}
        />

        <TemplateDropdown
          id="setupInvertSecondaryTextColor"
          path={paths.INVERT_SECONDARY_COLOR}
          label="event.template.page.setup.invert-colors.invertSecondaryTextColor.label"
          onChange={onChange}
          options={invertionTextColourOptions}
        />
      </Form.Group>

      <TemplateAbout
        id="setupCampaignAbout"
        path={paths.CAMPAIGN_ABOUT}
        label="event.template.page.setup.event-default-text.label"
        onChange={onChange}
        validation={campaignAboutValidation}
        entity={entityTypes.EVENT}
      />

      <TemplateHorizontalRule />

      <TemplateHeader
        as="h3"
        label="template.page.content.setup.time-and-place.header"
      />

      <TemplateCheckbox
        id="fundraisersFundraising"
        path={paths.ALLOW_TIME_AND_PLACE}
        inputLabel="event.template.page.setup.time-and-place.checkbox.label"
        onChange={onAllowTimeAndPlaceChange}
      />

      <Form.Group
        widths="equal"
        path="dateAndTime"
        validation={[
          getChildValidation(paths.START_DATE, {
            startDateRange: true,
            optionalParamPath: paths.END_DATE
          })
        ]}
      >
        <TemplateDatePicker
          id="setupStartDate"
          path={paths.START_DATE}
          label="event.template.page.setup.time-and-place.start-date.label"
          subtext="event.template.page.setup.time-and-place.start-date.subtext"
          onChange={onChange}
          onClear={() => onChange('timeAndPlace.time.start', null)}
          validation={{
            startDateRange: true,
            optionalParamPath: paths.END_DATE
          }}
        />

        <TemplateTimePicker
          id="setupStartTime"
          path="timeAndPlace.time.start"
          label="event.template.page.setup.time-and-place.time.label"
          subtext="event.template.page.setup.time-and-place.time.subtext"
          onChange={onChange}
        />
      </Form.Group>

      <Form.Group widths="equal" path="dateAndTime">
        <TemplateDatePicker
          id="setupEndDate"
          path={paths.END_DATE}
          label="event.template.page.setup.time-and-place.end-date.label"
          subtext="event.template.page.setup.time-and-place.end-date.subtext"
          onChange={onChange}
          onClear={() => onChange('timeAndPlace.time.end', null)}
          validation={{
            endDateRange: true,
            optionalParamPath: paths.START_DATE
          }}
        />

        <TemplateTimePicker
          id="setupEndTime"
          path="timeAndPlace.time.end"
          label="event.template.page.setup.time-and-place.time.label"
          subtext="event.template.page.setup.time-and-place.time.subtext"
          onChange={onChange}
        />
      </Form.Group>

      <TemplateInput
        id="setupTimeDetails"
        path={campaignContract.timeDetails}
        label="template.page.content.setup.time-and-place.time-details.label"
        placeholder="template.page.content.setup.time-and-place.time-details.placeholder"
        validation={timeDetailsValidation}
        onChange={onChange}
      />

      <TemplateInput
        id="setupLocation"
        path={campaignContract.place}
        label="event.template.page.setup.time-and-place.location-description.label"
        subtext="event.template.page.setup.time-and-place.location-description.subtext"
        validation={locationValidation}
        onChange={onChange}
      />

      <TemplateAddressAutocomplete
        onChange={onCityAutoCompleteChange}
        label="event.template.page.setup.time-and-place.city-location.label"
        subtext="event.template.page.setup.time-and-place.city-location.subtext"
        path="eventLocation"
        disableOnBlur
        removePlaceholder
        allowFilteringByCities
      />
    </TemplatePage>
  );
};
