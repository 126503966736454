import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import * as generalActions from '../../modules/general';
import * as donationActions from './store/donationActions';
import { bindActionCreators } from 'redux';
import { Button, Modal } from 'semantic-ui-react';
import { Record } from '../../modules/bin/utility';
import { grecaptcha } from '../../helpers/grecaptcha';

// TODO: separate into two windows - refund/donate
class RefundWindow extends Component {
  onRefundClick = () => {
    const tokenCallback = (token) =>
      this.props.refundDonation(this.props.refundRecord.id, token);
    grecaptcha(tokenCallback, "DonationUpdate");
  };

  onDeleteClick = () => {
    this.props.deleteOfflineDonation(this.props.refundRecord.id);
  };

  onRefundConfirmClose = () => {
    this.props.closeRefundConfirmation();
  };

  clearMessages = () => {
    this.props.clearRecordMessages('saveRecord');
  };

  render() {
    const email = get(this.props.refundRecord, 'donor.email');
    const amount = get(this.props.refundRecord, 'amount');
    const receiptNumber = get(this.props.refundRecord, 'receiptNumber');
    const alreadyRefunded = get(this.props.refundRecord, 'financials.refunded');
    const isOffline = get(this.props.refundRecord, 'type') === 'offline';
    const isPledge = get(this.props.refundRecord, 'type') === 'pledge';
    const isOnline = get(this.props.refundRecord, 'type') === 'online';
    const isRefunding = Record.isRecordLoading(this.props.saveRecord);

    const onlineRefundedErrorMessage = `Donation #${receiptNumber} has already been refunded`;
    const offlineRefundedErrorMessage = `Donation #${receiptNumber} has already been cancelled`;

    const isRefunded = this.props.isRefunded;
    if (isRefunded) {
      this.props.generalActions.clearListsReadyState();
      this.props.clearIsRefunded();
    }

    const isDeleted = this.props.isDeleted;
    if (isDeleted && window) {
      this.props.generalActions.clearListsReadyState();
      this.props.clearIsDeleted();
    }

    return (
      <Modal
        className="wizard"
        size="tiny"
        dimmer="inverted"
        closeIcon
        open={this.props.refundConfirmation}
      >
        <Modal.Header>
          {isOffline ? 'Confirmation required' : 'Refund confirmation'}
        </Modal.Header>

        {!alreadyRefunded && !isOffline && !isPledge && (
          <Modal.Content>
            Are you sure you want to refund ${amount}
            {email && ' to '}
            {email && email} ?
            <div
              className="button-wrapper align-right"
              style={{ marginTop: '10px' }}
            >
              <Button
                primary
                onClick={this.onRefundClick}
                style={{ marginRight: '10px' }}
                loading={isRefunding}
                disabled={isRefunding}
              >
                Yes
              </Button>
              <Button onClick={this.onRefundConfirmClose}>No</Button>
            </div>
          </Modal.Content>
        )}
        {!alreadyRefunded && isOffline && (
          <Modal.Content>
            Are you sure you want to delete this offline donation?
            <div
              className="button-wrapper align-right"
              style={{ marginTop: '10px' }}
            >
              <Button
                primary
                onClick={this.onDeleteClick}
                style={{ marginRight: '10px' }}
                loading={isRefunding}
                disabled={isRefunding}
              >
                Yes
              </Button>
              <Button onClick={this.onRefundConfirmClose}>No</Button>
            </div>
          </Modal.Content>
        )}
        {!alreadyRefunded && isPledge && (
          <Modal.Content>
            Are you sure you want to delete this pledge donation?
            <div
              className="button-wrapper align-right"
              style={{ marginTop: '10px' }}
            >
              <Button
                primary
                onClick={this.onDeleteClick}
                style={{ marginRight: '10px' }}
                loading={isRefunding}
                disabled={isRefunding}
              >
                Yes
              </Button>
              <Button onClick={this.onRefundConfirmClose}>No</Button>
            </div>
          </Modal.Content>
        )}
        {alreadyRefunded && (
          <Modal.Content>
            {isOnline && onlineRefundedErrorMessage}
            {!isOnline && offlineRefundedErrorMessage}
            <div
              className="button-wrapper align-right"
              style={{ marginTop: '10px' }}
            >
              <Button primary onClick={this.onRefundConfirmClose}>
                Ok
              </Button>
            </div>
          </Modal.Content>
        )}
      </Modal>
    );
  }
}

const mapState = ({ donation, session, account }) => {
  return {
    saveRecord: donation.saveRecord,
    refundRecord: donation.refundDonation,
    isRefunded: donation.isRefunded,
    isDeleted: donation.isDeleted,
    refundConfirmation: donation.refundConfirmation
  };
};

const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(donationActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch)
  };
};

const RefundWindowContainer = connect(mapState, mapDispatch)(RefundWindow);
export default RefundWindowContainer;
