import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import * as templateBuilderActions from '../../modules/template-builder-ex';
import TemplateBuilderContainerEx from '../../components/template/builder/indexEx';
import { TemplatePage } from '../../components/common/pager';
import { TemplateHeader } from '../../components/template/templateHeader';
import { TemplateInput } from '../../components/template/templateInput';
import { TemplateCheckbox } from '../../components/template/templateCheckbox';
import { TemplateDropdown } from '../../components/template/templateDropdown';
import { countries } from '../../constants/countries';
import {
  orgDetailComponents,
  australianOrgDetailComponents,
  organizationAboutValidationModels,
  organizationComplianceValidationModels,
  complianceComponents
} from './constants';
import TemplateEditableUpload from '../../components/template/templateEditableUpload';
import { TemplateColorPicker } from '../../components/template/templateColorPicker';
import { TemplateText } from '../../components/template/templateText';
import { imageTypes, entityTypes } from '../../constants/imageUpload';
import { Form } from 'semantic-ui-react';
import TemplateStripeConnection from './template-stripe-connection';
import TemplateSalesforceConnection from './template-salesforce-connection';
import { withRouter } from 'react-router-dom';
import { imageFormats } from '../../constants/imageUpload';
import config from '../../constants';
import { TemplateCustomVideoSelector } from '../../components/template/custom/templateCustomVideoSelector';
import { TemplateCustomMediaSelector } from '../../components/template/custom/templateCustomMediaSelector';
import { TemplateDefaultAmounts } from '../../components/template/custom/templateDefaultAmounts';
import { invertionTextColourOptions } from '../campaign/constants';
import { TemplateHorizontalRule } from '../../components/template/templateHorizontalRule';
import { getTimezoneOptions } from '../../helpers/timezoneOptionsHelper';
import * as templateHelper from '../../components/template/templateHelper';

import { useDonationSubscriptionSettings } from '../../components/template/useDonationSubscriptionSettings';
import { WebTrackingTemplate } from "../../components/webTracking/web-tracking";
import { InfoBox } from '../../components/common/infoBox';
import { Tabs } from '../../components/common/tabs';

class OrganizationTemplateView extends Component {
  constructor(props) {
    super(props);

    this.required = {
      required: true
    };

    this.optional = {
      required: false
    };

    this.url = {
      required: true,
      url: true
    };

    this.optionalUrl = {
      url: true
    };

    this.defaultAmountsValidation = {
      defaultAmounts: true
    };

    this.photoPhormats = imageFormats;

    this.domainValidation = {
      required: true,
      domainUrl: true
    };

    this.state = {
      selectedDollarHandlesTab: "once-off",
    };

    if (!this.hasCountryValue(this.props)) {
      this.props.hideControls(orgDetailComponents);
    }
  }

  onChange = (path, value, item) => {
    this.props.setResultValue(path, value);
  };

  onCountryChange = (path, value, item) => {
    if (value && !this.hasCountryValue(this.props)) {
      this.props.showControls(orgDetailComponents);
    }

    if (countries[value] !== countries.AU) {
      this.props.hideControls(australianOrgDetailComponents);
    } else {
      this.props.showControls(australianOrgDetailComponents);
    }

    if (countries[value] !== countries.NZ) {
      this.props.hideControls(complianceComponents, true);
    } else {
      this.props.showControls(complianceComponents);
    }

    this.props.setResultValue(path, value);
  };

  getCountryOptions() {
    return Object.keys(countries).map((key) => {
      return { text: countries[key].label, value: key };
    });
  }

  getDashboardOptions() {
    const titlePath = 'template.organisation.content.about.dashboard';
    return [
      { text: I18n.t(`${titlePath}.default`), value: null },
      { text: I18n.t(`${titlePath}.amountRaised`), value: 'amountRaised' },
      { text: I18n.t(`${titlePath}.alphabetical`), value: 'alphabetical' }
    ];
  }

  hasCountryValue = (props) => {
    return props.address && props.address.country;
  };

  getTaxFieldTextDescriptor = () => {
    if (this.props.address && this.props.address.country) {
      return countries[this.props.address.country].taxDescriptor;
    }
    return countries.AU.taxDescriptor;
  };

  handleCountryFocus = (evt) => {
    if (evt.target.getAttribute('autocomplete') === 'off') {
      evt.target.setAttribute('autocomplete', 'no');
    }
  };

  getColoursValidation = () => {
    const validationModel = [
      this.getChildValidation('template.value.primaryColor', this.required),
      this.getChildValidation('template.value.secondaryColor', this.required)
    ];
    return validationModel;
  };

  getChildValidation = (path, validation) => {
    return {
      path,
      validation
    };
  };

  onUseAsCustomOrganizationChange = (path, value) => {
    this.props.changeItemVisibility(
      'template.value.customDomain.domain',
      value,
      true
    );
    this.props.setResultValue(path, value);
  };

  onAllowCRMIntegrationChange = (path, value) => {
    this.props.changeItemVisibility('crmPlatforms', value, false);
    this.props.setResultValue(path, value);
  };

  onPreviewDashboardClick = () => {
    window.open(this.props.organization?.urlFull, '_blank');
  };

  render() {
    const country = this.props.address && this.props.address.country;
    const countryKey = countries[country]?.key || countries[0];

    return (
      <TemplateBuilderContainerEx
        onSave={this.props.onSave}
        isLoading={this.props.isLoading}
      >
        <TemplatePage
          id="about"
          i18nKey="template.organisation.content.about.header"
        >
          <TemplateDropdown
            id="aboutCountry"
            path="address.country"
            search
            label="template.organisation.content.about.country.label"
            subtext="template.organisation.content.about.country.subtext"
            validation={organizationAboutValidationModels.country}
            onChange={this.onCountryChange}
            getOptions={this.getCountryOptions}
            onFocus={this.handleCountryFocus}
          />

          <TemplateHeader
            as="h3"
            path="organizationDetilHeader"
            label="template.organisation.content.about.organisation-details-header"
          />

          <TemplateInput
            id="aboutOrganizationName"
            path="name"
            label="template.organisation.content.about.name.label"
            subtext="template.organisation.content.about.name.subtext"
            validation={organizationAboutValidationModels.name}
            onChange={this.onChange}
          />

          <TemplateInput
            id="aboutOrganisationLegalName"
            path="legalName"
            label="template.organisation.content.about.legal-name.label"
            subtext="template.organisation.content.about.legal-name.subtext"
            validation={organizationAboutValidationModels.legalName}
            onChange={this.onChange}
          />

          <TemplateInput
            id="aboutSubdomain"
            path="urlPath"
            label="template.organisation.content.about.subdomain.label"
            subtext="template.organisation.content.about.subdomain.subtext"
            validation={organizationAboutValidationModels.subdomain}
            onChange={this.onChange}
            subtextTranslationObject={{ domain: config.DOMAIN_URL }}
          />

          <TemplateInput
            id="aboutABN"
            path="taxId"
            label={`template.organisation.content.about.${this.getTaxFieldTextDescriptor()}.label`} //'template.organisation.content.about.abn.label'
            subtext={`template.organisation.content.about.${this.getTaxFieldTextDescriptor()}.subtext`} //'template.organisation.content.about.abn.subtext'
            validation={organizationAboutValidationModels.abn}
            onChange={this.onChange}
          />

          <TemplateCheckbox
            id="aboutDGR"
            path="template.value.hasDGR"
            inputLabel="template.organisation.content.about.dgr.label"
            subtext="template.organisation.content.about.dgr.subtext"
            onChange={this.onChange}
          />

          <TemplateInput
            id="aboutStreet"
            path="address.street"
            label="template.organisation.content.about.street.label"
            validation={organizationAboutValidationModels.street}
            onChange={this.onChange}
          />

          <TemplateInput
            id="aboutCity"
            path="address.city"
            label={`template.organisation.content.about.city.${countryKey}.label`}
            validation={organizationAboutValidationModels.city}
            onChange={this.onChange}
          />

          <TemplateInput
            id="aboutState"
            path="address.state"
            label={`template.organisation.content.about.state.${countryKey}.label`}
            validation={organizationAboutValidationModels.state}
            onChange={this.onChange}
          />

          <TemplateInput
            id="aboutPostcode"
            path="address.postcode"
            label="template.organisation.content.about.postcode.label"
            validation={organizationAboutValidationModels.postCode}
            onChange={this.onChange}
          />

          <TemplateDropdown
            id="aboutTimeZone"
            path="timezone"
            search
            label="template.organisation.content.about.timezone.label"
            subtext="template.organisation.content.about.timezone.subtext"
            validation={organizationAboutValidationModels.timeZone}
            onChange={this.onChange}
            getOptions={getTimezoneOptions}
          />

          {(this.props.isSystemAdmin || this.props.isOrgAdmin) && (
            <div path="publicDashboard">
              <TemplateHeader
                as="h3"
                path="organizationDetilHeader"
                label="template.organisation.content.about.dashboard-header"
              />

              <TemplateHeader
                as="h5"
                label="template.organisation.content.about.dashboard-subHeader"
              />

              <div className="field">
                <Button onClick={this.onPreviewDashboardClick}>
                  View my public dashboard
                </Button>
              </div>

              <TemplateDropdown
                id="aboutDashboardSort"
                defaultLabel="template.organisation.content.about.dashboard.default"
                path="template.value.dashboardSettings.sort"
                label="template.organisation.content.about.dashboard.label"
                onChange={this.onChange}
                getOptions={this.getDashboardOptions}
              />
            </div>
          )}
        </TemplatePage>

        <TemplatePage
          id="branding"
          i18nKey="template.organisation.content.branding.header"
        >
          <TemplateHeader
            as="h3"
            label="template.organisation.content.branding.header"
          />

          <InfoBox
            header="template.organisation.content.branding.info-box.header"
            content="template.organisation.content.branding.info-box.content"
          />

          <TemplateInput
            id="brandingOrganisationWebsite"
            path="template.value.organisationWebsite"
            label="template.organisation.content.branding.website.label"
            subtext="template.organisation.content.branding.website.subtext"
            validation={this.url}
            onChange={this.onChange}
          />

          <TemplateInput
            id="brandingMission"
            path="template.value.missionStatement"
            label="template.organisation.content.branding.mission.label"
            subtext="template.organisation.content.branding.mission.subtext"
            validation={this.required}
            onChange={this.onChange}
          />

          <TemplateEditableUpload
            id="brandingLogo"
            path="mainImagePath"
            entity={entityTypes.ORGANIZATION}
            position="left"
            height="350"
            width="350"
            format={this.photoPhormats}
            uploadType={imageTypes.LOGO}
            label="template.organisation.content.branding.organisation-logo.label"
            subtext="template.organisation.content.branding.organisation-logo.subtext"
            validation={this.required}
            onChange={this.onChange}
          />

          <Form.Group validation={this.getColoursValidation()} widths="equal">
            <TemplateColorPicker
              id="brandingPrimaryColor"
              path="template.value.primaryColor"
              label="template.organisation.content.branding.color-primary.label"
              subtext="template.organisation.content.branding.color-primary.subtext"
              validation={this.required}
              onChange={this.onChange}
            />

            <TemplateColorPicker
              id="brandingSecondaryColor"
              path="template.value.secondaryColor"
              label="template.organisation.content.branding.color-secondary.label"
              subtext="template.organisation.content.branding.color-secondary.subtext"
              validation={this.required}
              onChange={this.onChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <TemplateDropdown
              id="invertPrimaryTextColor"
              path="template.value.invertPrimaryTextColor"
              label="template.organisation.content.branding.invert-colors.invertPrimaryTextColor.label"
              onChange={this.onChange}
              options={invertionTextColourOptions}
            />

            <TemplateDropdown
              id="invertSecondaryTextColor"
              path="template.value.invertSecondaryTextColor"
              label="template.organisation.content.branding.invert-colors.invertSecondaryTextColor.label"
              onChange={this.onChange}
              options={invertionTextColourOptions}
            />
          </Form.Group>
        </TemplatePage>

        <TemplatePage
          id="advanced"
          i18nKey="template.organisation.content.advanced.page-title"
        >
          <WebTrackingTemplate
            type="organisation"
            path="template.value.webTracking"
            onChange={this.onChange}
          />
        </TemplatePage>

        <TemplatePage
          id="donation-forms"
          path="donation-forms"
          i18nKey="template.organisation.content.donation-forms.header"
        >
          <TemplateHeader
            as="h3"
            label="template.organisation.content.donation-forms.header"
          />

          <TemplateHeader
            as="h5"
            label="template.organisation.content.donation-forms.amounts.header"
          />
          
          <InfoBox
            header="template.organisation.content.donation-forms.amounts.info-box.label"
            content="template.organisation.content.donation-forms.amounts.info-box.subtext"
          />
          
          <Tabs
            buttons={[
              { key: "once-off", label: "Once-off" },
              { key: "monthly", label: "Monthly" },
            ]}
            selectedTab={this.state.selectedDollarHandlesTab}
            onChangeSelectedTab={(newSelectedTab) => {
              this.setState({
                ...this.state,
                selectedDollarHandlesTab: newSelectedTab,
              });
            }}
          />

          <div hidden={this.state.selectedDollarHandlesTab !== "once-off"}>
            <TemplateDefaultAmounts
              id="onceOffDonationDefaultAmounts"
              path="template.value.donationSetup.defaultAmounts"
              validation={this.defaultAmountsValidation}
              onChange={this.onChange}
            />
          </div>

          <div hidden={this.state.selectedDollarHandlesTab !== "monthly"}>
            <TemplateDefaultAmounts
              id="recurringDonationDefaultAmounts"
              path="template.value.recurringDonationSetup.defaultAmounts"
              validation={this.defaultAmountsValidation}
              onChange={this.onChange}
            />

            <TemplateInput
              id="donationPageText"
              path="template.value.recurringDonationSetup.donationPageText"
              label="template.organisation.content.donation-forms.donation-page-text.label"
              subtext="template.organisation.content.donation-forms.donation-page-text.subtext"
              validation={this.optional}
              onChange={this.onChange}
            />

            <TemplateEditableUpload
              id="donationHeroImage"
              path="template.value.recurringDonationSetup.donationHeroImage"
              entity={entityTypes.ORGANIZATION}
              position="top"
              height="350"
              width="1500"
              format={this.photoPhormats}
              uploadType={imageTypes.HERO_IMAGE}
              label="template.organisation.content.donation-forms.hero-image.label"
              subtext="template.organisation.content.donation-forms.hero-image.subtext"
              validation={this.optional}
              onChange={this.onChange}
            />

            <TemplateInput
              id="donationThankYouMessage"
              path="template.value.recurringDonationSetup.thankYouMessage"
              label="template.organisation.content.donation-forms.thank-you-message.label"
              subtext="template.organisation.content.donation-forms.thank-you-message.subtext"
              validation={this.optional}
              onChange={this.onChange}
            />

            <TemplateHeader
              as="h5"
              label="template.organisation.content.donation-forms.thank-you-media-heading.label"
            />

            <TemplateCustomMediaSelector
              onChange={this.onChange}
              path="template.value.recurringDonationSetup.selectedMediaType"
            >
              <TemplateCustomVideoSelector
                id="donationVideo"
                label="Video URL"
                radioLabel="template.organisation.content.donation-forms.donation-video.label"
                path="template.value.recurringDonationSetup.thankYouVideoUrl"
                subtext="template.organisation.content.donation-forms.donation-video.subtext"
                onChange={this.onChange}
              />

              <TemplateEditableUpload
                id="donationImage"
                path="template.value.recurringDonationSetup.thankYouImageUrl"
                radioLabel="template.organisation.content.donation-forms.donation-image.label"
                entity={entityTypes.ORGANIZATION}
                position="top"
                height="350"
                width="1500"
                format={this.photoPhormats}
                disableCropping
                uploadType={imageTypes.THANK_YOU}
                onChange={this.onChange}
              />
            </TemplateCustomMediaSelector>

            <TemplateInput
              id="urlToReturn"
              path="template.value.recurringDonationSetup.urlToReturn"
              label="template.organisation.content.donation-forms.urlToReturn.label"
              subtext="template.organisation.content.donation-forms.urlToReturn.subtext"
              validation={this.optionalUrl}
              onChange={this.onChange}
            />

            <br />
            <TemplateHeader
              as="h3"
              label="template.organisation.content.donation-forms.behaviour.label"
            />
            <TemplateDropdown
              id="donationSubscriptionSettings"
              path="donationSubscriptionSettings"
              label="template.organisation.content.donation-forms.receipt.label"
              subtext="template.organisation.content.donation-forms.receipt.subtext"
              {...useDonationSubscriptionSettings({
                value: this.props.donationSubscriptionSettings,
                onChange: this.onChange
              })}
            />
          </div>
        </TemplatePage>

        <TemplatePage
          id="email-notification"
          path="email-notification"
          i18nKey="template.organisation.content.email-notifications.header"
        >
          <TemplateHeader
            as="h3"
            label="template.organisation.content.email-notifications.header"
          />

          <TemplateText
            size="small"
            label="template.organisation.content.email-notifications.subheader"
          />

          <TemplateHeader
            as="h4"
            label="template.organisation.content.email-notifications.accounting.title"
          />

          <TemplateInput
            id="newInvoiceEmail"
            path="newInvoiceEmail"
            label="template.organisation.content.email-notifications.accounting.invoices-and-accounting.label"
            subtext="template.organisation.content.email-notifications.accounting.invoices-and-accounting.subtext"
            onChange={this.onChange}
          />

          <TemplateHeader
            as="h4"
            label="template.organisation.content.email-notifications.platform.title"
          />

          <TemplateText
            size="small"
            label="template.organisation.content.email-notifications.platform.subtext"
          />

          <TemplateInput
            path="dailySummaryEmail"
            label="template.organisation.content.email-notifications.platform.daily-summary.label"
            subtext="template.organisation.content.email-notifications.platform.daily-summary.subtext"
            onChange={this.onChange}
          />

          <TemplateInput
            id="emailNotificationsFundraisingPageCreatedEmail"
            path="fundraisingPageCreatedEmail"
            label="template.organisation.content.email-notifications.platform.fundraising-page-created-email.label"
            subtext="template.organisation.content.email-notifications.platform.fundraising-page-created-email.subtext"
            onChange={this.onChange}
          />

          <TemplateInput
            id="emailNotificationsNewDonationEmail"
            path="newDonationEmail"
            label="template.organisation.content.email-notifications.platform.new-donation-email.label"
            subtext="template.organisation.content.email-notifications.platform.new-donation-email.subtext"
            onChange={this.onChange}
          />

          <TemplateInput
            id="emailNotificationsNewSaleEmail"
            path="newSaleEmail"
            label="template.organisation.content.email-notifications.platform.new-sale-email.label"
            subtext="template.organisation.content.email-notifications.platform.new-sale-email.subtext"
            onChange={this.onChange}
          />          

          <TemplateInput
            id="emailNotificationsAmbassadorProgramEmail"
            path="ambassadorProgramEmail"
            label="template.organisation.content.email-notifications.platform.ambassador-program-email-email.label"
            subtext="template.organisation.content.email-notifications.platform.ambassador-program-email-email.subtext"
            onChange={this.onChange}
          />        

          <TemplateInput
            id="emailNotificationsAmbassadorProgramContactEmail"
            path="ambassadorProgramContactEmail"
            label="template.organisation.content.email-notifications.platform.ambassador-program-email-contact-email.label"
            subtext="template.organisation.content.email-notifications.platform.ambassador-program-email-contact-email.subtext"
            onChange={this.onChange}
          />
        </TemplatePage>

        <TemplatePage
          id="compliance"
          path="compliance"
          i18nKey="template.organisation.content.compliance.header"
        >
          <TemplateHeader
            as="h3"
            label="template.organisation.content.compliance.alternate-label"
          />

          <TemplateText
            size="small"
            label="template.organisation.content.compliance.alternate-description"
          />

          <TemplateInput
            path="compliance.alternateTaxId1"
            label={`template.organisation.content.compliance.alternateTaxId1.${country}`}
            onChange={this.onChange}
          />

          <TemplateInput
            path="compliance.alternateTaxId2"
            label={`template.organisation.content.compliance.alternateTaxId2.${country}`}
            onChange={this.onChange}
          />

          <TemplateHorizontalRule />
          <TemplateHeader
            as="h3"
            label="template.organisation.content.compliance.signature-label"
          />

          <TemplateInput
            path="compliance.authorizedPerson.name"
            label={`template.organisation.content.compliance.authorizedPerson.name.${country}`}
            onChange={this.onChange}
            validation={
              organizationComplianceValidationModels.complianceAuthorizedPersonName
            }
          />

          <TemplateInput
            path="compliance.authorizedPerson.title"
            label={`template.organisation.content.compliance.authorizedPerson.title.${country}`}
            onChange={this.onChange}
            validation={
              organizationComplianceValidationModels.complianceAuthorizedPersonTitle
            }
          />

          <TemplateEditableUpload
            path="compliance.authorizedPerson.signatureImagePath"
            entity={entityTypes.ORGANIZATION}
            position="top"
            height="350"
            width="1500"
            format={this.photoPhormats}
            uploadType={imageTypes.ASSET}
            label={`template.organisation.content.compliance.authorizedPerson.signature.${country}`}
            validation={
              organizationComplianceValidationModels.complianceAuthorizedPersonSignature
            }
            onChange={this.onChange}
          />
        </TemplatePage>

        {this.props.isSystemAdmin && (
          <TemplatePage
            id="eyes-only"
            i18nKey="template.organisation.content.eyes-only.page-title"
          >
            <TemplateHeader
              as="h3"
              label="template.organisation.content.eyes-only.custom-campaigns.header"
            />

            <TemplateCheckbox
              id="useAsCustomOrganization"
              path="template.value.customDomain.useAsCustomOrganization"
              inputLabel="template.organisation.content.eyes-only.custom-campaigns.checkbox"
              onChange={this.onUseAsCustomOrganizationChange}
            />

            <TemplateInput
              id="customUrlDomain"
              path="template.value.customDomain.domain"
              label="template.organisation.content.eyes-only.custom-campaigns.domain.label"
              subtext="template.organisation.content.eyes-only.custom-campaigns.domain.subtext"
              subtextPosition="bottom"
              validation={this.domainValidation}
              onChange={this.onChange}
            />

            <TemplateHorizontalRule path="template.value.allowCRMIntegration" />

            <TemplateHeader
              as="h3"
              path="template.value.allowCRMIntegration"
              label="template.organisation.content.eyes-only.allowCRMIntegration.header"
            />

            <TemplateCheckbox
              id="allowCRMIntegration"
              path="template.value.allowCRMIntegration"
              inputLabel="template.organisation.content.eyes-only.allowCRMIntegration.checkbox"
              onChange={this.onAllowCRMIntegrationChange}
            />

            <TemplateHorizontalRule path="template.value.donationSetup.disableBankAccountPayment" />

            <TemplateHeader
              as="h3"
              path="template.value.donationSetup.disableBankAccountPayment"
              label="template.organisation.content.eyes-only.donation-setup.header"
            />

            <TemplateCheckbox
              id="disableBankAccountPayment"
              path="template.value.donationSetup.disableBankAccountPayment"
              inputLabel="template.organisation.content.eyes-only.donation-setup.checkbox"
              onChange={this.onChange}
            />
          </TemplatePage>
        )}
        <TemplatePage
          id="finance"
          i18nKey="template.organisation.content.finance.page-title"
        >
          <TemplateHeader
            as="h3"
            label="template.organisation.content.finance.header"
          />

          <TemplateStripeConnection path="paymentPlatforms" />
        </TemplatePage>
        <TemplatePage
          id="crm"
          i18nKey="template.organisation.content.crm.page-title"
        >
          <TemplateHeader
            as="h3"
            label="template.organisation.content.crm.header"
          />

          <TemplateSalesforceConnection path="crmPlatforms" />
        </TemplatePage>
      </TemplateBuilderContainerEx>
    );
  }
}

const mapState = (state) => {
  return {
    address: state.templateBuilderEx.data.address,
    isSystemAdmin: state.session?.isSystemAdmin,
    isOrgAdmin: state.session?.isOrganizationAdmin,
    donationSubscriptionSettings: templateHelper.getValue(
      state.templateBuilderEx,
      'donationSubscriptionSettings'
    ),
    organization: state.organization?.record?.data?.data
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(templateBuilderActions, dispatch);
};

const OrganizationTemplate = withRouter(
  connect(mapState, mapDispatch)(OrganizationTemplateView)
);
export default OrganizationTemplate;
