import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import get from 'lodash.get';

import * as donationActions from '../../donation/store/donationActions';
import clear from '../../../components/clear';

import BasePageView from '../../../components/common/basePageView';
import DonationHeader from './donationHeader';
import { DonationDetails } from './donationDetails';
import RefundWindowContainer from '../refund-window';
import ReceiptWindowContainer from '../receipt-window';
import { DonationShortDescription } from './donationShortDescription';
import { DonationPaymentDetails } from './donationPaymentDetails';
import { DonationAdditionalInformation } from './donationAdditionalInformation.js';
import { DonationPageInformation } from './donationPageInformation';
import { DonationAction } from './donationAction';

import { Record } from '../../../modules/bin/utility';

import config from '../../../constants';

class DonationViewPageContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowEmptyFields: false,
      isOrganizationLoaded: false
    };
  }

  componentDidMount() {
    const donationId = this.props.match.params.id;
    this.props.donationActions.getRecord(donationId);
  }

  getOrganizationRecord = () => {
    const donation = get(this.props.record, 'data.data');
    const organizationId = get(donation, 'organizationId');

    this.setState({ isOrganizationLoaded: true });
    this.props.donationActions.getOrganizationRecord(organizationId);
  };

  onRefundClick = (item) => {
    this.props.donationActions.showRefundConfirmation(item);
  };

  changeVisibilityEmptyFields = () => {
    this.setState({ isShowEmptyFields: !this.state.isShowEmptyFields });
  };

  onReissueClick = (item) => {
    if (item.email) {
      this.props.donationActions.showReceiptConfirmation(item);
    } else {
      this.props.donationActions.getReceiptMessageError('saveRecord');
    }
  };

  render() {
    const donation = Record.isRecordReady(this.props.record)
      ? this.props.record.data.data
      : {};
    const organization = Record.isRecordReady(this.props.organizationRecord)
      ? this.props.organizationRecord.data.data
      : {};

    const paymentPlatforms = get(organization, 'paymentPlatforms');

    return (
      <BasePageView
        isLoading={this.props.isLoading}
        isLoadingError={this.props.isLoadingError}
        backLink={config.DEFAULT_DONATIONS_URL}
        backLinkTitle="Back to donations"
      >
        <RefundWindowContainer />
        <ReceiptWindowContainer />

        <div className="view-detail">
          <DonationHeader
            donation={donation}
            isShowEmptyFields={this.state.isShowEmptyFields}
            changeVisibilityEmptyFields={this.changeVisibilityEmptyFields}
          />
          <div className="view-detail-wrapper">
            <div className="view-detail-main-section">
              <DonationShortDescription
                donation={donation}
                isShowEmptyFields={this.state.isShowEmptyFields}
              />
              <DonationDetails
                donation={donation}
                isShowEmptyFields={this.state.isShowEmptyFields}
              />
              <DonationAdditionalInformation
                donation={donation}
                isShowEmptyFields={this.state.isShowEmptyFields}
              />
              <DonationPageInformation donation={donation} />
            </div>
            <div className="view-detail-side-section">
              <DonationPaymentDetails
                donation={donation}
                paymentPlatforms={paymentPlatforms}
                isSystemAdmin={this.props.isSystemAdmin}
                isOrganizationLoaded={this.state.isOrganizationLoaded}
                getOrganizationRecord={this.getOrganizationRecord}
              />
              <DonationAction
                donation={donation}
                openModalOnReissueClick={this.onReissueClick}
                openModalOnRefundClick={this.onRefundClick}
              />
            </div>
          </div>
        </div>
      </BasePageView>
    );
  }
}

const mapState = ({ donation, session }) => {
  return {
    isLoading: Record.isRecordLoading(donation.record),
    isSystemAdmin: session.isSystemAdmin,
    record: donation.record,
    saveRecord: donation.saveRecord,
    organizationRecord: donation.organizationRecord
  };
};

const mapDispatch = (dispatch) => {
  return {
    donationActions: bindActionCreators(donationActions, dispatch)
  };
};

const DonationViewPage = withRouter(
  clear(connect(mapState, mapDispatch)(DonationViewPageContainer))
);
export default DonationViewPage;
