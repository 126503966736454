import get from 'lodash.get';
import {
  getCustomFieldsWithAnswers,
  getQuestionsFromModel
} from '../../../helpers/formBuilderHelper';
import update from '../../../helpers/update';
import * as entityHandlers from '../../../modules/bin/entityHandlers';
import { Record } from '../../../modules/bin/utility';
import {
  getMerchandiseContract,
  getTicketContract,
  saleContract
} from './../ticketingContracts';

import { CLEAR, LIST_READY_STATE_CLEAR } from '../../../modules/general';
import {
  DELETE_IMPERSONATE_ADMIN_SUCCESS,
  IMPERSONATE_ADMIN_SUCCESS
} from '../../../modules/session';

import { fieldCategoryKey } from '../../../constants/formBuilder';
import { gridKeys } from '../../../constants/gridKeys';
import { isArrayWithItems } from '../../../helpers/arrayHelper';
import { listReadyStateClear } from '../../../helpers/donationHelper';
import { ON_VALUE_CHANGE } from '../../../modules/authentication';
import { gridTabs } from './../constants';
import {
  getMerchandiseGrid,
  getSalesGrid,
  getTicketsGrid
} from './../gridConfiguration';
import { paths } from './paths';
import {
  ACTIVE_TAB_SET,
  CREATE_FAILURE,
  CREATE_MANUAL_INSTALLMENT_REQUESTED,
  CREATE_MANUAL_INSTALLMENT_SUCCESS,
  CREATE_REQUESTED,
  CREATE_SUCCESS,
  ERROR_CLEAR,
  GET_AVAILABLE_TICKETS_FAILURE,
  GET_AVAILABLE_TICKETS_REQUESTED,
  GET_AVAILABLE_TICKETS_SUCCESS,
  GET_CAMPAIGN_FAILURE,
  GET_CAMPAIGN_REQUESTED,
  GET_CAMPAIGN_SUCCESS,
  GET_EXPORT_FILE_FAILURE,
  GET_EXPORT_FILE_REQUESTED,
  GET_EXPORT_FILE_SUCCESS,
  GET_SALE_FAILURE,
  GET_SALE_RELATED_FAILURE,
  GET_SALE_RELATED_REQUESTED,
  GET_SALE_RELATED_SUCCESS,
  GET_SALE_REQUESTED,
  GET_SALE_SUCCESS,
  GET_TICKETS_FAILURE,
  GET_TICKETS_REQUESTED,
  GET_TICKETS_SUCCESS,
  IS_REFUNDED_CLEAR,
  LIST_DATA_FAILURE,
  LIST_DATA_REQUESTED,
  LIST_DATA_SUCCESS,
  MERCHANDISE_REFUND_SUCCESS,
  METADATA_FAILURE,
  METADATA_REQUESTED,
  METADATA_SUCCESS,
  RECEIPT_CONFIRMATION_HIDE,
  RECEIPT_CONFIRMATION_SHOW,
  RECEIPT_MESSAGE_ERROR,
  REFUND_CONFIRMATION_HIDE,
  REFUND_CONFIRMATION_SHOW,
  SALE_RECEIPT_FAILURE,
  SALE_RECEIPT_REQUESTED,
  SALE_RECEIPT_SUCCESS,
  SALE_REFUND_AND_CANCEL_FAILURE,
  SALE_REFUND_AND_CANCEL_REQUESTED,
  SALE_REFUND_AND_CANCEL_SUCCESS,
  SALE_REFUND_FAILURE,
  SALE_REFUND_REQUESTED,
  SALE_REFUND_SUCCESS,
  TICKET_RECEIPT_REQUESTED,
  TICKET_RECEIPT_SUCCESS,
  TICKET_REFUND_REQUESTED,
  TICKET_REFUND_SUCCESS,
  TOGGLE_COLUMNS_CHANGE,
  UPDATE_FAILURE,
  UPDATE_REQUESTED,
  UPDATE_SUCCESS,
  GET_ORG_REQUEST,
  GET_ORG_FAILURE,
  GET_ORG_SUCCESS
} from './ticketingTypes';

const i18nSalesListKey = 'sale.list.header';
const i18nTicketsListKey = 'ticket.list.header';
const i18nMerchandiseListKey = 'merchandise.list.header';

const initialState = {
  selectedView: gridTabs.SALES,
  record: Record.getDefaultState(),
  saveRecord: Record.getDefaultState(),
  ticketsRecord: Record.getDefaultState(),
  relatedRecord: Record.getDefaultState(),
  exportFileRecord: Record.getDefaultState(),
  manualInstallmentRecord: Record.getDefaultState(),
  [gridKeys.SALES]: getSalesGrid(i18nSalesListKey),
  [gridKeys.TICKETS]: getTicketsGrid(i18nTicketsListKey),
  [gridKeys.MERCHANDISE]: getMerchandiseGrid(i18nMerchandiseListKey),
  sale: {} as any
};

export default (state = initialState, action: any) => {
  let newState = state;

  switch (action.type) {
    case REFUND_CONFIRMATION_SHOW:
    case RECEIPT_CONFIRMATION_SHOW:
      newState = update.set(
        newState,
        action.payload.recordPath,
        action.payload.value
      );
      newState = update.set(newState, action.payload.confirmationPath, true);
      break;

    case RECEIPT_CONFIRMATION_HIDE:
    case REFUND_CONFIRMATION_HIDE:
      newState = update.set(newState, action.payload.confirmationPath, false);
      break;

    case GET_SALE_REQUESTED:
    case SALE_REFUND_AND_CANCEL_REQUESTED:
    case GET_TICKETS_REQUESTED:
    case CREATE_REQUESTED:
    case UPDATE_REQUESTED:
    case SALE_REFUND_REQUESTED:
    case TICKET_REFUND_REQUESTED:
    case SALE_RECEIPT_REQUESTED:
    case TICKET_RECEIPT_REQUESTED:
    case GET_CAMPAIGN_REQUESTED:
    case GET_AVAILABLE_TICKETS_REQUESTED:
    case GET_EXPORT_FILE_REQUESTED:
    case GET_SALE_RELATED_REQUESTED:
      newState = update.set(newState, paths.collectPaymentConfirmation, false);
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case GET_SALE_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);

      const sale = get(newState, 'record.data.data') as any;
      //sale
      const salePhoneNumber = get(sale, 'customer.phoneNumber');
      const saleAddress = get(sale, 'customer.address');
      const saleQuestions = getQuestionsFromModel(
        get(sale, `campaign`),
        fieldCategoryKey.SALES
      );
      const saleFields = get(sale, 'fields');
      const saleCustomFieldsWithAnswers = getCustomFieldsWithAnswers(
        saleQuestions,
        saleFields,
        salePhoneNumber,
        saleAddress
      );

      newState = update.set(
        newState,
        `record.data.data.${saleContract.formbuilder}`,
        saleCustomFieldsWithAnswers
      );

      //tickets & merchandise
      if (sale && isArrayWithItems(sale.details)) {
        for (let index = 0; index < sale.details.length; index++) {
          const phoneNumber = get(
            sale,
            `details.${index}.customer.phoneNumber`
          );
          const address = get(sale, `details.${index}.customer.address`);
          const fields = get(sale, `details.${index}.fields`);
          if (get(sale, `details.${index}.ticket`)) {
            const ticketContract = getTicketContract(index);
            const ticketQuestions = get(sale, ticketContract.formbuilder);
            const ticketCustomFieldsWithAnswers = getCustomFieldsWithAnswers(
              ticketQuestions,
              fields,
              phoneNumber,
              address
            );
            newState = update.set(
              newState,
              `record.data.data.${ticketContract.formbuilder}`,
              ticketCustomFieldsWithAnswers
            );
          }
          if (get(sale, `details.${index}.merchandiseSku`)) {
            const merchandiseContract = getMerchandiseContract(index);
            const merchandiseQuestions = get(
              sale,
              merchandiseContract.formbuilder
            );
            const merchandiseCustomFieldsWithAnswers =
              getCustomFieldsWithAnswers(
                merchandiseQuestions,
                fields,
                phoneNumber,
                address
              );
            newState = update.set(
              newState,
              `record.data.data.${merchandiseContract.formbuilder}`,
              merchandiseCustomFieldsWithAnswers
            );
          }
        }
      }
      break;

    case GET_TICKETS_SUCCESS:
    case UPDATE_SUCCESS:
    case CREATE_SUCCESS:
    case GET_CAMPAIGN_SUCCESS:
    case GET_AVAILABLE_TICKETS_SUCCESS:
    case GET_EXPORT_FILE_SUCCESS:
    case GET_SALE_RELATED_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;

    case GET_SALE_RELATED_FAILURE:
    case GET_SALE_FAILURE:
    case GET_TICKETS_FAILURE:
    case CREATE_FAILURE:
    case UPDATE_FAILURE:
    case GET_CAMPAIGN_FAILURE:
    case GET_AVAILABLE_TICKETS_FAILURE:
    case GET_EXPORT_FILE_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;

    case SALE_REFUND_SUCCESS:
    case SALE_REFUND_AND_CANCEL_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(newState, 'sale.refundConfirmation', false);
      newState = update.set(newState, 'sale.isRefunded', true);
      break;

    case TICKET_REFUND_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(newState, 'ticket.refundConfirmation', false);
      newState = update.set(newState, 'ticket.isRefunded', true);
      break;

    case MERCHANDISE_REFUND_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(newState, 'merchandise.refundConfirmation', false);
      newState = update.set(newState, 'merchandise.isRefunded', true);
      break;

    case SALE_REFUND_FAILURE:
    case SALE_REFUND_AND_CANCEL_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      newState = update.set(newState, 'sale.refundConfirmation', false);
      break;

    case SALE_REFUND_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      newState = update.set(newState, 'ticket.refundConfirmation', false);
      break;

    case SALE_RECEIPT_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(newState, 'sale.reissueConfirmation', false);
      break;

    case TICKET_RECEIPT_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(newState, 'ticket.reissueConfirmation', false);
      break;

    case SALE_RECEIPT_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      newState = update.set(newState, 'sale.reissueConfirmation', false);
      break;

    case SALE_RECEIPT_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      newState = update.set(newState, 'ticket.reissueConfirmation', false);
      break;

    case IS_REFUNDED_CLEAR:
      newState = update.set(
        newState,
        `${action.payload.entity}.isRefunded`,
        false
      );
      break;

    case ERROR_CLEAR:
      newState = entityHandlers.crudClearErrorHandler(
        state,
        action.payload.key
      );
      break;

    case CLEAR:
    case DELETE_IMPERSONATE_ADMIN_SUCCESS:
    case IMPERSONATE_ADMIN_SUCCESS:
      newState = initialState;
      break;

    case CREATE_MANUAL_INSTALLMENT_REQUESTED:
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case CREATE_MANUAL_INSTALLMENT_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(newState, paths.collectPaymentWindowIsOpen, false);
      newState = update.set(newState, paths.collectPaymentConfirmation, false);
      newState = update.set(newState, paths.paymentUrlWindowIsOpen, true);
      break;

    case METADATA_REQUESTED:
      newState = entityHandlers.metadataRequestedHandler(state, action);
      break;

    case METADATA_SUCCESS:
      newState = entityHandlers.metadataSuccessHandler(state, action);
      break;

    case LIST_DATA_REQUESTED:
      newState = entityHandlers.listDataRequestedHandler(state, action);
      break;

    case LIST_DATA_SUCCESS:
      newState = entityHandlers.listDataSuccessHandler(state, action);
      break;

    case LIST_DATA_FAILURE:
      newState = entityHandlers.listDataFailureHandler(state, action);
      break;

    case METADATA_FAILURE:
      newState = entityHandlers.listMetadataFailureHandler(state, action);
      break;

    case TOGGLE_COLUMNS_CHANGE:
      newState = entityHandlers.toggleColumnChangeHandler(state, action);
      break;

    case ACTIVE_TAB_SET:
      newState = update(newState, { selectedView: { $set: action.payload } });
      break;

    case RECEIPT_MESSAGE_ERROR:
      newState = entityHandlers.messageErrorHandler(state, action, [
        'receipt.absentEmail'
      ]);
      break;

    case LIST_READY_STATE_CLEAR:
      newState = listReadyStateClear(newState, gridKeys.SALES);
      newState = listReadyStateClear(newState, gridKeys.TICKETS);
      newState = listReadyStateClear(newState, gridKeys.MERCHANDISE);
      break;

    case ON_VALUE_CHANGE:
      newState = update.set(
        newState,
        action.payload.path,
        action.payload.value
      );
      break;

    case GET_ORG_REQUEST:
    case GET_ORG_SUCCESS:
    case GET_ORG_FAILURE:
      newState = update(newState, { asyncExport: { $set: action.payload } });
      break;

    default:
      return newState;
  }

  return newState;
};
