import React from 'react';

import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';

import { formatDecimalNumber } from '../../../../helpers/converterHelper';

export const DonationPlatformFeeSectionRow = (props) => {
  const { title, data } = props;

  const feeDetails = get(data, 'feeDetail');
  const fee = get(feeDetails, 'fee');
  const feeTax = get(feeDetails, 'feeTax');

  const feeString = `$${formatDecimalNumber(fee, true)}`;
  const feeTaxString = `$${formatDecimalNumber(feeTax, true)}`;

  // const feeCovered = get(data, 'feeCovered');
  // const feePercentage = get(feeDetails, 'feePercentage');

  return (
    <React.Fragment>
      <div className="view-details-content-row column">
        <h4>{I18n.t(title)}</h4>
        <div className="column-content">
          <span>{`${feeString} (includes ${feeTaxString} tax)`}</span>
          <span>Paid by donor</span>
          {/* {
                        !feeCovered &&
                        <span>{`${feePercentage} > 0`}</span>
                    }
                    {
                        feePercentage !== 0 && feePercentage > 0 &&
                        <span>{`${feePercentage * 100}% mandatory`}</span>
                    } */}
        </div>
      </div>
    </React.Fragment>
  );
};
