import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';

import { TicketingStatus } from '../components';
import { ViewSectionRow } from '../../../components/layouts/viewSectionRow';
import { ViewTable } from '../../../components/layouts/viewTable';
import { toCurrency } from '../../../helpers/converterHelper';
import { useAppDispatch } from '../../../hooks';
import { AppDispatch, RootState } from '../../../store';
import { getCampaignRecord } from '../../donation/store/donationActions';
import { DonationFinancialsPlatformSectionRow } from '../../donation/view/components/donationFinancialsPlatformSectionRow';
import { OFFLINE_SALE_TYPE } from '../../campaign/constants';

interface ITicketInvoiceTableProps {
  showEmptyFields: boolean;
}

export const TicketPaymentTable = ({
  showEmptyFields
}: ITicketInvoiceTableProps) => {
  const dispatch: AppDispatch = useAppDispatch();
  const ticketing = useSelector((state: RootState) => state.ticketing);
  const session = useSelector((state: RootState) => state.session);
  const donation = useSelector((state: RootState) => state.donation);
  const [isCampaignLoaded, setIsCampaignLoaded] = useState(false);
  const recordData = ticketing.record?.data?.data;
  const feeDetails = recordData?.financials?.feeDetail;

  const type = recordData.type;
  const isOffline = type == OFFLINE_SALE_TYPE;

  const donationPlatformsProps = useMemo(() => {
    const paymentPlatforms = donation.record?.data?.data?.paymentPlatforms;

    if (paymentPlatforms) {
      return {
        paymentPlatforms: [paymentPlatforms.main],
        alternativePaymentPlatforms: paymentPlatforms.alternative
      };
    }

    return [];
  }, [donation.record?.data?.data]);

  const getCampaign = () => {
    setIsCampaignLoaded(true);
    dispatch(getCampaignRecord(recordData?.campaignId));
  };

  return (
    <ViewTable
      title={
        <Translate value="ticketing.edit.invoice-information.payment.label" />
      }
    >
      <ViewSectionRow
        isShowEmptyFields={showEmptyFields}
        title="ticketing.edit.invoice-information.amount.label"
        data={toCurrency(recordData?.amount)}
      />
      <ViewSectionRow
        isShowEmptyFields={showEmptyFields}
        title="ticketing.edit.invoice-information.fee.stripe.label"
        data={toCurrency(
          feeDetails?.paymentPlatformFee + feeDetails?.paymentPlatformFeeTax
        )}
      />
      <ViewSectionRow
        isShowEmptyFields={showEmptyFields}
        title="ticketing.edit.invoice-information.fee.tax.label"
        data={
          <>
            {toCurrency(feeDetails?.fee)} ({toCurrency(feeDetails?.feeTax)})
          </>
        }
      />
      <ViewSectionRow
        isShowEmptyFields={showEmptyFields}
        title="ticketing.edit.invoice-information.fee.sales-tax.label"
        data={toCurrency(feeDetails?.saleTax)}
      />
      {!isOffline && 
          <DonationFinancialsPlatformSectionRow
            title="ticketing.edit.invoice-information.gateway.label"
            data={recordData?.financials}
            isPaymentPlatformsLoaded={isCampaignLoaded}
            getPaymentPlatforms={getCampaign}
            isSystemAdmin={session.isSystemAdmin}
            {...donationPlatformsProps}
          />
    }
    </ViewTable>
  );
};
