import React from 'react';
import { useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';

import { ViewSectionRow } from '../../../components/layouts/viewSectionRow';
import { ViewTable } from '../../../components/layouts/viewTable';
import { isArrayWithItems } from '../../../helpers/arrayHelper';
import { toCurrency } from '../../../helpers/converterHelper';
import { RootState } from '../../../store';
import { FormBuilderSectionRow } from '../../../components/layouts/formBuilderSectionRow';
import { LinkedEntities } from './LinkedEntities';
import { entityMap } from '../store/ticketingActions';
import { pageTypesStrings } from '../../../constants/pageTypes';
import { displayAddress } from '../../../helpers/templateHelper';

interface IMerchandiseInformationTableProps {
  showEmptyFields: boolean;
}

const MerchandiseInformation = ({ details, index, showEmptyFields }: any) => {
  const ticketing = useSelector((state: RootState) => state.ticketing);
  const address = details.customer?.address;
  const relatedData = ticketing.relatedRecord?.data?.data;

  const buildEntities = () => {
    if (relatedData?.fundraisers && details.id) {
      const campaign = relatedData?.campaign;
      const campaignFunraisers = relatedData.fundraisers.filter(
        (x: any) => x.saleDetailId === details.id
      );
      const tmp = entityMap(
        campaignFunraisers,
        pageTypesStrings.FUNDRAISER,
        campaign
      );
      return tmp;
    }
    return [];
  };

  return (
    <div style={{ marginTop: 15 }}>
      <ViewTable
        key={index}
        title={
          <Translate value="ticketing.edit.merchandise.header" index={index + 1} />
        }
      >
        <ViewSectionRow
          isShowEmptyFields={showEmptyFields}
          title="ticketing.edit.merchandise.label"
          data={
            <>
              {details.merchandiseSku?.name} ({toCurrency(details.price)})
            </>
          }
        />
        <ViewSectionRow
          isShowEmptyFields={showEmptyFields}
          title="ticketing.edit.merchandise.first-name.label"
          data={details.customer?.firstName}
        />
        <ViewSectionRow
          isShowEmptyFields={showEmptyFields}
          title="ticketing.edit.merchandise.last-name.label"
          data={details.customer?.lastName}
        />
        <ViewSectionRow
          isShowEmptyFields={showEmptyFields}
          title="ticketing.edit.merchandise.email.label"
          data={details.customer?.email}
        />
        <ViewSectionRow
          isShowEmptyFields={showEmptyFields}
          title="ticketing.edit.merchandise.name.label"
          data={displayAddress(address)}
        />
        {isArrayWithItems(details.fields) &&
          React.Children.toArray(
            details.fields.map((field: any) => (
              <FormBuilderSectionRow
                isShowEmptyFields={showEmptyFields}
                field={field}
              />
            ))
          )}
        <LinkedEntities entities={buildEntities()} />
      </ViewTable>
    </div>
  );
};

export const MerchandiseInformationTable = ({
  showEmptyFields
}: IMerchandiseInformationTableProps) => {
  const ticketing = useSelector((state: RootState) => state.ticketing);
  const recordData = ticketing.record?.data?.data || {};
  const details: any[] = recordData?.details || [];
  const merchandise = details.filter(item => item.merchandiseSkuId);

  return (
    <>
      {merchandise?.map((details: any, index: number) => (
        <MerchandiseInformation
          key={index}
          details={details}
          index={index}
          showEmptyFields={showEmptyFields}
        />
      ))}
    </>
  );
};
