import React from 'react';

import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';

import { formatDecimalNumber } from '../../../../helpers/converterHelper';

export const DonationEventOrganizerFeeSectionRow = (props) => {
  const { title, data } = props;

  const feeDetails = get(data, 'feeDetail');
  const eventOrganizerFee = get(feeDetails, 'eventOrganizerFee');
  const eventOrganizerFeeTax = get(feeDetails, 'eventOrganizerFeeTax');
  const eventOrganizerFeePercentage = get(
    feeDetails,
    'eventOrganizerFeePercentage'
  );

  const eventOrganizerFeeString = `$${formatDecimalNumber(
    eventOrganizerFee,
    true
  )}`;
  const eventOrganizerFeeTaxString = `$${formatDecimalNumber(
    eventOrganizerFeeTax,
    true
  )}`;

  return (
    <React.Fragment>
      <div className="view-details-content-row column">
        <h4>{I18n.t(title)}</h4>
        <div className="column-content">
          <span>{`${eventOrganizerFeeString} (includes ${eventOrganizerFeeTaxString} tax)`}</span>
          {eventOrganizerFeePercentage !== 0 &&
            eventOrganizerFeePercentage > 0 && (
              <span>{`${eventOrganizerFeePercentage * 100}% mandatory`}</span>
            )}
        </div>
      </div>
    </React.Fragment>
  );
};
