import config from '../../../constants';
import { generalFormBuilderPath } from '../../../constants/formBuilder';
import { pageTypesStrings } from '../../../constants/pageTypes';
import { getDataWithQuestionFields } from '../../../helpers/dataCaptureHelper';
import update from '../../../helpers/update';
import { ON_VALUE_CHANGE } from '../../../modules/authentication';
import * as entityHandlers from '../../../modules/bin/entityHandlers';
import { saleContract } from '../ticketingContracts';
import {
  ACTIVE_TAB_SET,
  CREATE_FAILURE,
  CREATE_MANUAL_INSTALLMENT_FAILURE,
  CREATE_MANUAL_INSTALLMENT_REQUESTED,
  CREATE_MANUAL_INSTALLMENT_SUCCESS,
  CREATE_REQUESTED,
  CREATE_SUCCESS,
  ERROR_CLEAR,
  GET_AVAILABLE_TICKETS_FAILURE,
  GET_AVAILABLE_TICKETS_REQUESTED,
  GET_AVAILABLE_TICKETS_SUCCESS,
  GET_CAMPAIGN_FAILURE,
  GET_CAMPAIGN_REQUESTED,
  GET_CAMPAIGN_SUCCESS,
  GET_EXPORT_FILE_FAILURE,
  GET_EXPORT_FILE_REQUESTED,
  GET_EXPORT_FILE_SUCCESS,
  GET_SALE_FAILURE,
  GET_SALE_RELATED_FAILURE,
  GET_SALE_RELATED_REQUESTED,
  GET_SALE_RELATED_SUCCESS,
  GET_SALE_REQUESTED,
  GET_SALE_SUCCESS,
  GET_TICKETS_FAILURE,
  GET_TICKETS_REQUESTED,
  GET_TICKETS_SUCCESS,
  IS_REFUNDED_CLEAR,
  LIST_DATA_FAILURE,
  LIST_DATA_REQUESTED,
  LIST_DATA_SUCCESS,
  MERCHANDISE_REFUND_FAILURE,
  MERCHANDISE_REFUND_REQUESTED,
  MERCHANDISE_REFUND_SUCCESS,
  METADATA_FAILURE,
  METADATA_REQUESTED,
  METADATA_SUCCESS,
  RECEIPT_CONFIRMATION_HIDE,
  RECEIPT_CONFIRMATION_SHOW,
  RECEIPT_MESSAGE_ERROR,
  REFUND_CONFIRMATION_HIDE,
  REFUND_CONFIRMATION_SHOW,
  SALE_RECEIPT_FAILURE,
  SALE_RECEIPT_REQUESTED,
  SALE_RECEIPT_SUCCESS,
  SALE_REFUND_AND_CANCEL_FAILURE,
  SALE_REFUND_AND_CANCEL_REQUESTED,
  SALE_REFUND_AND_CANCEL_SUCCESS,
  SALE_REFUND_FAILURE,
  SALE_REFUND_REQUESTED,
  SALE_REFUND_SUCCESS,
  TICKET_RECEIPT_FAILURE,
  TICKET_RECEIPT_REQUESTED,
  TICKET_RECEIPT_SUCCESS,
  TICKET_REFUND_FAILURE,
  TICKET_REFUND_REQUESTED,
  TICKET_REFUND_SUCCESS,
  TOGGLE_COLUMNS_CHANGE,
  UPDATE_FAILURE,
  UPDATE_REQUESTED,
  UPDATE_SUCCESS,
  GET_ORG_SUCCESS,
  GET_ORG_REQUEST,
  GET_ORG_FAILURE
} from './ticketingTypes';

export const onValueChange = (path: string, value: any) => {
  return {
    type: ON_VALUE_CHANGE,
    payload: {
      path,
      value
    }
  };
};

export const showReceiptConfirmation = (item: any, entity: string) => {
  return {
    type: RECEIPT_CONFIRMATION_SHOW,
    payload: {
      recordPath: `${entity}.reissueRecord`,
      confirmationPath: `${entity}.reissueConfirmation`,
      value: item
    }
  };
};

export const hideReceiptConfirmation = (entity: string) => {
  return {
    type: RECEIPT_CONFIRMATION_HIDE,
    payload: {
      confirmationPath: `${entity}.reissueConfirmation`
    }
  };
};

export const showRefundConfirmation = (item: any, entity: string) => {
  return {
    type: REFUND_CONFIRMATION_SHOW,
    payload: {
      recordPath: `${entity}.refundRecord`,
      confirmationPath: `${entity}.refundConfirmation`,
      value: item
    }
  };
};

export const closeRefundConfirmation = (entity: string) => {
  return {
    type: REFUND_CONFIRMATION_HIDE,
    payload: {
      confirmationPath: `${entity}.refundConfirmation`
    }
  };
};

export const clearIsRefunded = (entity: string) => ({
  type: IS_REFUNDED_CLEAR,
  payload: { entity: entity }
});

export const clearRecordMessages = (record: string) => ({
  type: ERROR_CLEAR,
  payload: { key: record }
});

export const refundAndCancelSale = (id: string, refundAmount?: number) => {
  return entityHandlers.deleteRecord(
    'sale',
    SALE_REFUND_AND_CANCEL_REQUESTED,
    SALE_REFUND_AND_CANCEL_SUCCESS,
    SALE_REFUND_AND_CANCEL_FAILURE,
    refundAmount ? `${id}?refundAmount=${refundAmount}` : id,
    'saveRecord'
  );
};

export const refundTicket = (id: string, verificationToken?: string) => {
  return entityHandlers.deleteRecord(
    'saleDetail',
    TICKET_REFUND_REQUESTED,
    TICKET_REFUND_SUCCESS,
    TICKET_REFUND_FAILURE,
    id,
    'saveRecord',
    verificationToken || null,
  );
};

export const refundMerchandise = (id: string) => {
  return entityHandlers.deleteRecord(
    'saleDetail',
    MERCHANDISE_REFUND_REQUESTED,
    MERCHANDISE_REFUND_SUCCESS,
    MERCHANDISE_REFUND_FAILURE,
    id,
    'saveRecord'
  );
};

export const refundSale = (id: string, refundAmount: number) => {
  return entityHandlers.executeRecordRequest(
    `${config.baseApiHost}/api/v2/sale/${id}/refund`,
    'POST',
    SALE_REFUND_REQUESTED,
    SALE_REFUND_SUCCESS,
    SALE_REFUND_FAILURE,
    'saveRecord',
    {
      refundAmount
    }
  );
};

export const reissueSale = (id: string) => {
  return entityHandlers.executeRecordRequest(
    `${config.baseApiHost}/api/v2/sale/${id}/invoice`,
    'POST',
    SALE_RECEIPT_REQUESTED,
    SALE_RECEIPT_SUCCESS,
    SALE_RECEIPT_FAILURE,
    'saveRecord'
  );
};

export const reissueTicket = (record: any) =>
  entityHandlers.executeRequest(
    `${config.baseApiHost}/api/v2/saleDetail/${record.id}/customer`,
    'PUT',
    TICKET_RECEIPT_REQUESTED,
    TICKET_RECEIPT_SUCCESS,
    TICKET_RECEIPT_FAILURE,
    record,
    (_state: any, data: any) => {
      // data = getDataWithQuestionFields(data, `ticket.${generalFormBuilderPath}`, 'customer.address', 'customer.phoneNumber');
      return update(data, {
        issueTicket: { $set: true }
      });
    },
    'saveRecord'
  );

export const getSaleRecord = (id: string) => {
  return entityHandlers.getRecord(
    'sale',
    GET_SALE_REQUESTED,
    GET_SALE_SUCCESS,
    GET_SALE_FAILURE,
    id
  );
};

export const getSaleRelatedRecord = (id: string) => {
  return entityHandlers.getRecord(
    'sale',
    GET_SALE_RELATED_REQUESTED,
    GET_SALE_RELATED_SUCCESS,
    GET_SALE_RELATED_FAILURE,
    `${id}/related`,
    'relatedRecord'
  );
};

export const createManualInstallmentRecord = (id: string) => {
  return entityHandlers.createRecord(
    `sale/${id}/installment/manual`,
    CREATE_MANUAL_INSTALLMENT_REQUESTED,
    CREATE_MANUAL_INSTALLMENT_SUCCESS,
    CREATE_MANUAL_INSTALLMENT_FAILURE,
    null,
    null,
    'manualInstallmentRecord'
  );
};

export const getTicketsRecord = (id: string) => {
  return entityHandlers.getRecord(
    'saleDetail',
    GET_TICKETS_REQUESTED,
    GET_TICKETS_SUCCESS,
    GET_TICKETS_FAILURE,
    id
  );
};

export const createRecord = (record: any, verificationToken?: string) =>
  entityHandlers.createRecord(
    'sale',
    CREATE_REQUESTED,
    CREATE_SUCCESS,
    CREATE_FAILURE,
    record,
    (_state: any, data: any) => {
      data = update.set(data, 'type', 'offline');

      data = getDataWithQuestionFields(
        data,
        saleContract.formbuilder,
        'customer.address',
        'customer.phoneNumber'
      );
      if (data.details && data.details.length) {
        for (let index = 0; index < data.details.length; index++) {
          data.details[index] = getDataWithQuestionFields(
            data.details[index],
            `ticket.${generalFormBuilderPath}`,
            'customer.address',
            'customer.phoneNumber'
          );
        }
      }

      return data;
    },
    'saveRecord',
    'v2/',
    verificationToken || null,
  );

export const updateSaleRecord = (record: any) =>
  entityHandlers.executeRequest(
    `${config.baseApiHost}/api/v2/sale/${record.id}/customer`,
    'PUT',
    UPDATE_REQUESTED,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    record,
    (_state: any, data: any) => {
      data = getDataWithQuestionFields(
        data,
        saleContract.formbuilder,
        'customer.address',
        'customer.phoneNumber'
      );
      return data;
    },
    'saveRecord'
  );

export const updateTicketRecord = (record: any) =>
  entityHandlers.executeRequest(
    `${config.baseApiHost}/api/v2/saleDetail/${record.id}/customer`,
    'PUT',
    UPDATE_REQUESTED,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    record,
    (_state: any, data: any) => {
      data = getDataWithQuestionFields(
        data,
        `ticket.${generalFormBuilderPath}`,
        'customer.address',
        'customer.phoneNumber'
      );
      return data;
    },
    'saveRecord'
  );

export const getCampaignRecord = (id: string) =>
  entityHandlers.getRecord(
    'page/campaign',
    GET_CAMPAIGN_REQUESTED,
    GET_CAMPAIGN_SUCCESS,
    GET_CAMPAIGN_FAILURE,
    id
  );

export const getAvailableTicketsRecord = (request: any) =>
  entityHandlers.getSearchRecord(
    'ticket',
    GET_AVAILABLE_TICKETS_REQUESTED,
    GET_AVAILABLE_TICKETS_SUCCESS,
    GET_AVAILABLE_TICKETS_FAILURE,
    request,
    'ticketsRecord'
  );

export const toggleColumnsChange = (listKey: string, toggleColumns: any) =>
  entityHandlers.toggleColumnsChange(
    listKey,
    toggleColumns,
    TOGGLE_COLUMNS_CHANGE
  );

export const getSaleMetadata = (listKey: string) =>
  entityHandlers.getMetadata(
    'sale',
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );

export const getSaleListData = (
  listKey: string,
  id: string,
  request: any,
  invalidate = false,
  cachedData?: any
) =>
  entityHandlers.getListData(
    'sale',
    listKey,
    id,
    LIST_DATA_REQUESTED,
    LIST_DATA_SUCCESS,
    LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData,
    'v3'
  );

export const getSaleListDataBySearchTerm = (
  listKey: string,
  id: string,
  request: any,
  searchTerm: string
) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getSaleListData(listKey, id, newRequest, true);
};

export const getTicketMetadata = (listKey: string) =>
  entityHandlers.getMetadata(
    'saleDetail',
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );

export const getMerchandiseMetadata = (listKey: string) =>
  entityHandlers.getMetadata(
    'saleDetail',
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );

export const getSaleDetailListData = (
  listKey: string,
  id: string,
  request: any,
  invalidate = false,
  cachedData?: any
) =>
  entityHandlers.getListData(
    'saleDetail',
    listKey,
    id,
    LIST_DATA_REQUESTED,
    LIST_DATA_SUCCESS,
    LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData,
    'v3'
  );

function addListFilter(filters: any[] = [], filter: any) {
  const hasFilter = filters.some((item) => item.key === filter.key);
  return hasFilter ? filters : [...filters, filter];
}

export const getTicketListData = (
  listKey: string,
  id: string,
  request: any = {},
  invalidate = false,
  cachedData?: any
) => {
  const filters = addListFilter(request?.filters, {
    key: 'type',
    operator: 'EqualTo',
    value: ['ticket']
  });
  return getSaleDetailListData(
    listKey,
    id,
    {
      ...request,
      filters
    },
    invalidate,
    cachedData
  );
};

export const getMerchandiseListData = (
  listKey: string,
  id: string,
  request: any = {},
  invalidate = false,
  cachedData?: any
) => {
  const filters = addListFilter(request?.filters, {
    key: 'type',
    operator: 'EqualTo',
    value: ['merchandise']
  });
  return getSaleDetailListData(
    listKey,
    id,
    {
      ...request,
      filters
    },
    invalidate,
    cachedData
  );
};

export const getTicketListDataBySearchTerm = (
  listKey: string,
  id: string,
  request: any,
  searchTerm: string
) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getTicketListData(listKey, id, newRequest, true);
};

export const getMerchandiseListDataBySearchTerm = (
  listKey: string,
  id: string,
  request: any,
  searchTerm: string
) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getMerchandiseListData(listKey, id, newRequest, true);
};

export const setView = (view: any) => ({ type: ACTIVE_TAB_SET, payload: view });

export const getReceiptMessageError = (record: any) => ({
  type: RECEIPT_MESSAGE_ERROR,
  payload: { key: record }
});

// export const getExportFile = (
//   entity: any,
//   request: any,
//   fileName: string,
//   version: string
// ) => {
//   return entityHandlers.getExportFile(
//     entity,
//     GET_EXPORT_FILE_REQUESTED,
//     GET_EXPORT_FILE_SUCCESS,
//     GET_EXPORT_FILE_FAILURE,
//     request,
//     'exportFileRecord',
//     fileName,
//     version
//   );
// };

export interface ICreateAsyncExportArgs {
  entity: any;
  request: any;
  fileName: string;
  version: string;
}

export const createAsyncExport = ({
  entity,
  request,
  fileName,
  version
}: ICreateAsyncExportArgs) => {
  return entityHandlers.createAsyncExport(
    entity,
    GET_EXPORT_FILE_REQUESTED,
    GET_EXPORT_FILE_SUCCESS,
    GET_EXPORT_FILE_FAILURE,
    request,
    'exportFileRecord',
    fileName,
    version
  );
};

export const getAsyncExport = (
  entity: any,
  key: string,
) => {
  return entityHandlers.getAsyncExport(
    entity,
    GET_EXPORT_FILE_REQUESTED,
    GET_EXPORT_FILE_SUCCESS,
    GET_EXPORT_FILE_FAILURE,
    key,
    'exportFileRecord'
  );
};



export const unknownEntityMap = (entity: any, type: pageTypesStrings) =>
  entity?.map((item: any) => ({
    type: type,
    data: {
      id: item.id,
      amount: item.amount
    }
  })) || [];

export const entityMap = (entity: any, type: pageTypesStrings, campaign: any) =>
  entity?.map((item: any) => ({
    type,
    imageUrl: `${item.mainImageUrl || campaign?.mainImageUrl}?w=100`,
    data: {
      id: item.id,
      name: item.name,
      urlFull: item.urlFull || campaign?.urlFull
    }
  })) || [];

export const getOrgRecord = (id: string) => {
  return entityHandlers.getRecord(
    'organization',
    GET_ORG_REQUEST,
    GET_ORG_SUCCESS,
    GET_ORG_FAILURE,
    id,
  );
};
