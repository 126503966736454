import React from 'react';

import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';

import { formatDecimalNumber } from '../../../../helpers/converterHelper';

export const DonationPlatformSilentFeeSectionRow = (props) => {
  const { title, data } = props;

  const feeDetails = get(data, 'feeDetail');
  const silentFee = get(feeDetails, 'silentFee');
  const silentFeeTax = get(feeDetails, 'silentFeeTax');

  const silentFeeString = `$${formatDecimalNumber(silentFee, true)}`;
  const silentFeeTaxString = `$${formatDecimalNumber(silentFeeTax, true)}`;

  const silentFeePercentage = get(feeDetails, 'silentFeePercentage');

  return (
    <React.Fragment>
      <div className="view-details-content-row column">
        <h4>{I18n.t(title)}</h4>
        <div className="column-content">
          <span>{`${silentFeeString} (includes ${silentFeeTaxString} tax)`}</span>
          {silentFeePercentage !== 0 &&
            silentFeePercentage !== null &&
            silentFeePercentage > 0 && (
              <span>{`${silentFeePercentage * 100}% mandatory`}</span>
            )}
        </div>
      </div>
    </React.Fragment>
  );
};
