import React, { Component } from 'react';
import { connect } from 'react-redux';
import OptionalLabel from '../common/optionalLabel';

import * as templateHelper from './templateHelper';

import './index.css';
import ValidatedCurrency from '../common/validatedCurrency';

class ManagedCurrency extends Component {
  onValueChange = (evt) => {
    if (this.props.onChange) {
      const val = parseFloat(evt.target.value);
      this.props.onChange(this.props.path, val);
    }
  };

  render() {
    const {
      id,
      className,
      subtext,
      value,
      hideInputLabel,
      subtextPosition,
      validation,
      isDisabled,
      showValidationIcon,
      placeholder,
      allowDecimal,
      maxValue,
      inputLabel,
      width
    } = this.props;

    const label =
      validation && validation.required === false ? (
        <OptionalLabel>{this.props.label}</OptionalLabel>
      ) : (
        this.props.label
      );

    const inputCurrencyLabel = hideInputLabel ? '' : inputLabel || '$';

    return (
      <ValidatedCurrency
        id={id}
        className={className}
        disabled={isDisabled}
        label={label}
        subtext={subtext}
        subtextPosition={subtextPosition || 'bottom'}
        allowDecimal={allowDecimal}
        value={value}
        inputLabel={inputCurrencyLabel}
        style={{ width: width || '184px' }}
        onChange={this.onValueChange}
        required={validation && validation.required}
        validation={validation}
        placeholder={placeholder}
        showValidationIcon={showValidationIcon}
        maxValue={maxValue}
      />
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    label: templateHelper.getTranslation(ownProps.label),
    subtext: templateHelper.getTranslation(ownProps.subtext),
    value:
      ownProps.value ||
      templateHelper.getValue(state.templateBuilderEx, ownProps.path),
    isDisabled: templateHelper.getState(
      state.templateBuilderEx,
      ownProps.path,
      'disabled'
    ),
    placeholder: templateHelper.getTranslation(ownProps.placeholder)
  };
};

export const TemplateCurrency = connect(mapState)(ManagedCurrency);
