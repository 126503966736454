import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { I18n, Translate } from 'react-redux-i18n';

import { getSaleRecord, getSaleRelatedRecord } from '../store/ticketingActions';
import BasePageView from '../../../components/common/basePageView';
import { Record } from '../../../modules/bin/utility';
import { RouteParams } from '../../../containers/app';
import { RootState } from '../../../store';
import { useAppDispatch } from '../../../hooks';
import { TicketingStatus, TicketingHeader } from '../components';
import { Button } from 'semantic-ui-react';
import { TicketPaymentTable } from './TicketPaymentTable';
import { TicketInformationTable } from './TicketInformationTable';
import { TicketActions } from './TicketActions';
import { BillingInformationTable } from './BillingInformationTable';
import { TicketInstallmentTable } from './TicketInstallmentTable';
import { TicketCouponCodes } from './TicketCouponCodes';
import { CollectPaymentWindow } from '../modals/collectPaymentWindow';
import { TicketCollectPayment } from './TicketCollectPayment';
import { ViewPaymentUrlWindow } from '../modals/viewPaymentUrlWindow';
import { MerchandiseInformationTable } from './MerchandiseInformationTable';

const ViewTicketing = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const ticketing = useSelector((state: RootState) => state.ticketing);
  const { saleId } = useParams<RouteParams>();
  const [showEmptyFields, setShowEmptyFields] = useState(false);

  const isNotStarted = Record.notStarted(ticketing.record);
  const recordData = ticketing.record?.data?.data;
  const isInstallmentsEnabled = recordData?.financials?.installments?.enabled;
  const paymentCompleted =
    recordData?.financials?.installments?.paymentCompleted;
  const paymentCollectionStopped =
    recordData?.financials?.installments?.paymentCollectionStopped;
  const installmentManualPaymentRequests =
    ticketing?.relatedRecord?.data?.installmentManualPaymentRequests;

  useEffect(() => {
    if (!ticketing?.sale?.refundConfirmation) {
      dispatch(getSaleRecord(saleId));
      dispatch(getSaleRelatedRecord(saleId));
    } else if (ticketing?.sale?.collectPaymentWindow?.confirmation) {
      dispatch(getSaleRelatedRecord(saleId));
    }
  }, [
    ticketing?.sale?.refundConfirmation,
    ticketing?.sale?.collectPaymentWindow?.confirmation
  ]);

  const isLoading = useMemo(() => {
    return (
      Record.isRecordLoading(ticketing.record) ||
      Record.isRecordLoading(ticketing.manualInstallmentRecord)
    );
  }, [ticketing.record, ticketing.manualInstallmentRecord]);

  return (
    <div className="ui grid sale-detail">
      <BasePageView isLoading={isLoading || isNotStarted}>
        <div className="ticketing-header-container">
          <TicketingStatus ticketData={recordData} />

          <div
            className="ticketing-header-container"
            style={{ flexBasis: '42%' }}
          >
            <div className="action">
              <p
                className="Links"
                onClick={() => setShowEmptyFields(!showEmptyFields)}
              >
                {I18n.t(
                  `ticketing.edit.buttons.${
                    showEmptyFields ? 'hide' : 'show'
                  }-empty-fields-link`
                )}
              </p>
            </div>
            <div className="action">
              <Button
                className="edit-sale-button"
                primary
                onClick={() =>
                  history.push(`/sales/edit/${saleId}/invoice-information`)
                }
              >
                <Translate value="ticketing.edit.titles.view" />
              </Button>
            </div>
          </div>
        </div>

        <div className="view-detail">
          <div className="view-detail-wrapper">
            <div className="view-detail-main-section">
              <TicketingHeader ticketData={recordData} />
              <BillingInformationTable showEmptyFields={showEmptyFields} />
              <TicketInformationTable showEmptyFields={showEmptyFields} />
              <MerchandiseInformationTable showEmptyFields={showEmptyFields} />
            </div>
            <div className="view-detail-side-section ticketing-side-section">
              <TicketPaymentTable showEmptyFields={showEmptyFields} />
              <TicketInstallmentTable showEmptyFields={showEmptyFields} />
              {isInstallmentsEnabled && !paymentCompleted && (
                <TicketCollectPayment />
              )}
              <TicketCouponCodes />
              <TicketActions />
            </div>
          </div>
        </div>
        {paymentCollectionStopped && <ViewPaymentUrlWindow />}
        {isInstallmentsEnabled && !paymentCollectionStopped && (
          <CollectPaymentWindow />
        )}
      </BasePageView>
    </div>
  );
};

export default ViewTicketing;
